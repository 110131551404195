<!-- 七牛分片上传大视频 -->
<template>
  <div style="display:flex;alignItems:center">
    <div class="upload_box" :style="{width,height}">
      <input class="upload_input" type="file" id="file" value="点我上传" accept="video/*" name="file" @change="upload">
      <video v-if="url" :src="url" class="upload-one-component-image" autoplay :controls="false"></video>
      <i v-if="!url&&!schedule" class="el-icon-plus upload-one-component-icon"></i>
      <div v-if="!url&&schedule" class="schedule">{{schedule}}%</div>
    </div>
    <el-button v-if="schedule>0&&schedule<100" type="danger" style="marginLeft:20px" size="mini" @click="stopUpload">
      取消上传</el-button>
  </div>
</template>
<script>
import { getQiniuToken } from '@/api/common'
import { guid } from '@/utils/index'
import * as Qiniu from 'qiniu-js'
export default {
  name: 'UploadVideo',
  props: {
    url: {
      value: '',
    },
    width: {
      type: String,
      default: '120px',
    },
    height: {
      type: String,
      default: '120px',
    },
  },
  model: {
    prop: 'url', //绑定的值，通过父组件传递
    event: 'update', //自定义时间名
  },
  data() {
    return {
      loading: false,
      qiniu: {
        actionPath: '',
        baseUrl: '',
        postData: {},
      },
      config: {
        useCdnDomain: true,
        region: 'qiniu.region.z2', // 默认华南
        uphost: '',
        // chunkSize: 500,
      },
      subscription: null,
      schedule: 0,
    }
  },
  created() {
    getQiniuToken().then((response) => {
      // token可多次使用
      this.qiniu.actionPath = response.data.uploadUrl
      this.qiniu.baseUrl = response.data.baseUrl
      this.config.uphost = response.data.uploadUrl
      if (!this.qiniu.postData.token) {
        this.qiniu.postData.token = response.data.token
      }
    })
  },
  methods: {
    upload(e) {
      let that = this
      this.$emit('update', '')
      console.log(that.subscription)
      if(that.subscription&&that.subscription.observer.isStopped) that.subscription
      if (that.subscription) that.subscription.unsubscribe() // 如果有上传中的视频 取消上一个上传
      let files_url = URL.createObjectURL(e.target.files[0])
      var audioElement = new Audio(files_url)
      audioElement.addEventListener('loadedmetadata', function (_event) {
        console.log('视频时长：' + audioElement.duration + '秒')
        that.$emit('getDuration', audioElement.duration)
      })
      //　　next：函数类型参数，带有一个参数，上传过程中执行该函数，根据函数参数可以知道文件上传了多少
      //　　error：函数类型参数，带有一个参数，上传失败执行该函数，函数参数为上传失败返回值
      //　　complete：函数类型参数，带有一个参数，上传成功执行该函数，函数参数为上传成功返回值
      let putExtra = {
        //   fname: e.target.files[0].name,
      }
      let observable = Qiniu.upload(
        e.target.files[0],
        guid() + '.' + e.target.files[0].type.split('/')[1],
        this.qiniu.postData.token,
        this.config,
        putExtra
      )
      that.subscription = observable.subscribe({
        next(res) {
          that.schedule = res.total.percent.toFixed(2)
        },
        error(err) {
          console.log('错误')
          console.log(err)
        },
        complete(res) {
          console.log('完成')
          let url = that.qiniu.baseUrl + '/' + res.key
          console.log(url)
          that.$emit('update', url)
          // 上传完成清空
        //   that.subscription = null
        },
      })
      console.log('-------------')
      console.log(that.subscription)
      console.log(e.target.files[0])
    },
    stopUpload() {
      this.subscription.unsubscribe()
      document.getElementById('file').value = ''
      // this.subscription = null
      this.schedule = 0
    },
  },
}
</script>

<style rel="stylesheet/scss" lang="scss">
.upload_box {
  //   width: 200px;
  //   height: 200px;
  border: 1px dashed #d9d9d9;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  .upload_input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 99999;
  }
  .upload-one-component-icon {
    font-size: 30px;
    color: #8c939d;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .upload-one-component-image {
    width: 100%;
    height: 100%;
    display: block;
  }
  .schedule {
    width: 100%;
    height: 100%;
    color: #000;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.upload_box:hover {
  border-color: #409eff;
  .upload-one-component-icon {
    color: #409eff;
  }
}
</style>
