<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate"
        >新增</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="图片" min-width="100" align="center">
        <template slot-scope="scope">
          <!-- <img v-if="scope.row.img" :src="scope.row.img" class="image-url-150"> -->
          <el-image class="image-url-150" :src="scope.row.img" lazy :preview-src-list="[scope.row.img]"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="跳转类型" min-width="110" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.link_type == 0">不跳转</template>
          <template v-if="scope.row.link_type == 1">课程详情</template>
          <template v-else-if="scope.row.link_type == 2">活动详情</template>
          <template v-else-if="scope.row.link_type == 3">资讯详情</template>
          <template v-else-if="scope.row.link_type == 4">外部链接</template>
          <template v-else-if="scope.row.link_type == 5">公司详情</template>
        </template>
      </el-table-column>
       <el-table-column label="跳转链接"  min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.link }}
        </template>
      </el-table-column>
      <el-table-column label="排序" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.weight }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)"
            >修改</el-button
          >

          <el-button size="mini" type="danger" @click="handleDel(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="图片(690*316)" prop="img">
          <upload-one v-model="form.img" style="width:50%;height:50%"></upload-one>
        </el-form-item>
        <el-form-item label="排序" prop="weight">
          <el-input-number
            v-model="form.weight"
            :min="1"
            :max="9999"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="跳转类型" prop="link_type">
          <el-select v-model="form.link_type" clearable>
            <el-option label="不跳转" :value="0"></el-option>
            <el-option label="课程详情" :value="1"></el-option>
            <el-option label="活动详情" :value="2"></el-option>
            <el-option label="资讯详情" :value="3"></el-option>
            <el-option label="公司详情" :value="5"></el-option>
            <el-option label="外部链接" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="链接" prop="link" v-if="form.link_type">
          <el-input type="text" v-model="form.link" clearable />
          <div
            style="
              line-height: 16px;
              font-size: 12px;
              color: red;
              margin-top: 10px;
            "
          >
            <div>*课程详情，此处填课程id</div>
            <div>*活动详情，此处填活动id</div>
            <div>*资讯详情，此处填资讯id</div>
             <div>*公司详情，此处填公司id</div>
            <div>
              *外部链接，此处填链接url，需在微信小程序管理平台添加该业务域名
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
      },
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "修改",
        create: "新增",
      },
      form: {
        id: "",
        img: "",
        weight: 1,
        link_type: null,
        link: "",
      },
      rules: {
        img: [{ required: true, message: "图片不能为空", trigger: "change" }],
        weight: [
          { required: true, message: "排序不能为空", trigger: "change" },
        ],
        link_type: [
          { required: true, message: "跳转类型不能为空", trigger: "change" },
        ],
        // link: [{ required: true, message: '链接不能为空', trigger: 'change' }],
      },
      btnLoading: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/banner/list",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    resetForm() {
      this.form = {
        id: "",
        img: "",
        weight: 1,
        link_type: null,
        link: "",
      };
      this.btnLoading = false;
    },
    handleCreate() {
      this.resetForm();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    handleUpdate(row) {
      this.resetForm();
      this.form = Object.assign({}, row);
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    saveData() {
      if (this.form.link_type && !this.form.link) {
        this.$message({
          type: "error",
          message: "跳转链接不能为空",
        });
        return;
      }
      if(!this.form.link_type){
        this.form.link=''
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/backend/banner/save",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    handleDel(val) {
      this.$confirm("确定要删除, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/banner/del",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
