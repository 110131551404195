<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="listQuery.keyword" placeholder="请输入用户名/电话" style="width: 200px;" class="filter-item" clearable/>
      <el-button class="filter-item" type="primary" @click="handleFilter">搜索</el-button>
      <el-button class="filter-item" type="warning" @click="$router.go(-1)" style="float:right">返回</el-button>
    </div>
    <el-table
            v-loading="listLoading"
            :data="list"
            element-loading-text="Loading"
            border
            fit
            highlight-current-row>
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="用户ID" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.row.user?scope.row.user.id:'' }}
        </template>
      </el-table-column>
      <el-table-column label="头像" min-width="110" align="center">
        <template slot-scope="scope">
          <img v-if="scope.row.user && scope.row.user.avatar" :src="scope.row.user.avatar" class="user-avatar">
        </template>
      </el-table-column>
      <el-table-column label="昵称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user?scope.row.user.nickname:'' }}
        </template>
      </el-table-column>
      <el-table-column label="联系电话" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user?scope.row.user.phone:'' }}
        </template>
      </el-table-column>
      <el-table-column label="性别" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.user && scope.row.user.gender == 0" type="info">未设置</el-tag>
          <el-tag v-else-if="scope.row.user && scope.row.user.gender == 1" type="primary">男</el-tag>
          <el-tag v-else-if="scope.row.user && scope.row.user.gender == 2" type="danger">女</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="购买时间" min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
    </div>
  </div>
</template>

<script>

  import request from '@/utils/request'

  export default {
    data() {
      return {
        list: null,
        total: null,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 10,
          course_id: '',
          keyword: '',
        },
        course_id: '',
      }
    },
    created() {
      this.course_id = this.listQuery.course_id = this.$route.query.course_id
      if(!this.course_id){
        this.$message.warning('请先选择课程')
        setTimeout(()=>{
          this.$router.push(`/course/list`);
        },1000)
        return
      }
      this.getList()
    },
    methods: {
      getList() {
        this.listLoading = true
        request({
          url: '/api/backend/course/buyList',
          method: 'get',
          params: this.listQuery
        }).then(response => {
          this.list = response.data.data
          this.total = response.data.total
          this.listLoading = false
        })
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      handleSizeChange(val) {
        this.listQuery.limit = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.listQuery.page = val
        this.getList()
      },
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

</style>
