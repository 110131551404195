<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate">新增</el-button>
      <el-input v-model="listQuery.title" placeholder="请输入课程类型" style="width: 200px;" class="filter-item" clearable/>
      <el-select v-model="listQuery.is_on" placeholder="状态" clearable style="width: 150px" class="filter-item">
        <el-option label="正常" :value="1"/>
        <el-option label="已下架" :value="0"/>
      </el-select>
      <el-select v-model="listQuery.is_index_show" placeholder="显示" clearable style="width: 150px" class="filter-item">
        <el-option label="显示在首页" :value="1"/>
        <el-option label="不显示首页" :value="0"/>
      </el-select>
      <el-button class="filter-item" type="primary" @click="handleFilter">搜索</el-button>
    </div>
    <el-table
            v-loading="listLoading"
            :data="list"
            element-loading-text="Loading"
            border
            fit
            highlight-current-row>
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="课程类型" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.title }}
        </template>
      </el-table-column>
      <el-table-column label="图标" min-width="110" align="center">
        <template slot-scope="scope">
          <!-- <img v-if="scope.row.icon" :src="scope.row.icon" class="user-avatar"> -->
          <el-image class="image-url" fit="contain" :src="scope.row.icon" lazy></el-image>
        </template>
      </el-table-column>
      <el-table-column label="是否显示在首页" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_index_show == 1" type="success">是</el-tag>
          <el-tag v-else type="info">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="排序" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.sort_weight }}
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_on == 1" type="success">正常</el-tag>
          <el-tag v-else type="danger">已下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="280" align="center" class-name="small-padding fixed-width" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)">修改</el-button>

          <el-button v-if="scope.row.is_on == 1" size="mini" type="danger" @click="handleStatus(scope.row.id, '下架')">下架</el-button>
          <el-button v-else size="mini" type="success" @click="handleStatus(scope.row.id, '上架')">上架</el-button>

          <el-button v-if="scope.row.is_index_show == 1" size="mini" type="danger" @click="handleShow(scope.row.id, '取消显示在首页')">取消显示在首页</el-button>
          <el-button v-else size="mini" type="success" @click="handleShow(scope.row.id, '显示在首页')">显示在首页</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
    </div>

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="课程类型" prop="title">
          <el-input type="text" v-model="form.title" clearable />
        </el-form-item>
        <el-form-item label="图标(116*116)" prop="icon">
          <upload-one v-model="form.icon" style="width:116px;height:116px"></upload-one>
        </el-form-item>
        <el-form-item label="排序权重" prop="sort_weight">
          <el-input-number v-model="form.sort_weight" :min="1" :max="9999"></el-input-number>
        </el-form-item>
        <el-form-item label="是否显示在首页" prop="is_index_show">
          <el-radio v-model="form.is_index_show" :label="0">否</el-radio>
          <el-radio v-model="form.is_index_show" :label="1">是</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

  import request from '@/utils/request'

  export default {
    data() {
      return {
        list: null,
        total: null,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 10,
          title: '',
          is_on: '',
          is_index_show: '',
        },
        dialogFormVisible: false,
        dialogStatus: '',
        textMap: {
          update: '修改',
          create: '新增'
        },
        form: {
          id: '',
          title: '',
          icon: '',
          sort_weight: 1,
          is_index_show: 0,
        },
        rules: {
          title: [{ required: true, message: '课程类型不能为空', trigger: 'change' }],
          icon: [{ required: true, message: '图标不能为空', trigger: 'change' }],
          sort_weight: [{ required: true, message: '排序权重不能为空', trigger: 'change' }],
          is_index_show: [{ required: true, message: '是否显示在首页不能为空', trigger: 'change' }],
        },
        btnLoading: false,
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.listLoading = true
        request({
          url: '/api/backend/course/categoryList',
          method: 'get',
          params: this.listQuery
        }).then(response => {
          this.list = response.data.data
          this.total = response.data.total
          this.listLoading = false
        })
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      handleSizeChange(val) {
        this.listQuery.limit = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.listQuery.page = val
        this.getList()
      },
      resetForm() {
        this.form = {
          id: '',
          title: '',
          icon: '',
          sort_weight: 1,
          is_index_show: 0,
        }
        this.btnLoading = false
      },
      handleCreate() {
        this.resetForm()
        this.dialogStatus = 'create'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['form'].clearValidate()
        })
      },
      handleUpdate(row) {
        this.resetForm()
        this.form = Object.assign({}, row)
        this.dialogStatus = 'update'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['form'].clearValidate()
        })
      },
      saveData() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.btnLoading = true
            request({
              url: '/api/backend/course/categoryStore',
              method: 'post',
              data: this.form
            }).then(response => {
              this.btnLoading = false
              this.dialogFormVisible = false
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.getList();
            }).catch((err) => {
              this.btnLoading = false
            })
          }
        })
      },
      //上下架
      handleStatus(val,text) {
        this.$confirm('此操作将'+text+'该课程类型, 是否继续?', '提示', {
          type: 'warning'
        }).then(() => {
          request({
            url: '/api/backend/course/categoryUpDown',
            method: 'post',
            data: {id: val}
          }).then(()=>{
            this.getList()
            this.$message({
              type: 'success',
              message: '操作成功'
            });
          })
        }).catch(() => {});
      },
      //是否显示首页
      handleShow(val,text) {
        this.$confirm('此操作将课程类型'+text+', 是否继续?', '提示', {
          type: 'warning'
        }).then(() => {
          request({
            url: '/api/backend/course/categoryIndexShow',
            method: 'post',
            data: {id: val}
          }).then(()=>{
            this.getList()
            this.$message({
              type: 'success',
              message: '操作成功'
            });
          })
        }).catch(() => {});
      },
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

</style>
