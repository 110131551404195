<template>
    <div class="richtext-container">
        <div style="width:620px;">
            <p>兑换比率(1元=X豆)</p>
            <div style="display:flex;alignItems:center;justifyContent: space-between; width:620px;">
                <el-input style="width:570px;" v-model="form.value" placeholder="请输入单价" @input="form.value=form.value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'').replace('.','').replace(/\./g,'').replace('$#$','').replace(/\.{2,}/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2')"></el-input>
                <span>(豆/元)</span>
            </div>
            <div style="textAlign:right;marginTop:10px">
                <el-button type="primary" @click="handleSave">保存</el-button>
            </div>
        </div>
        <!-- <el-button type="danger" @click="handleClear">清空</el-button> -->
        <!-- <el-button style="marginTop:10px" type="primary" @click="handleSave">保存</el-button> -->

        <div style="width:620px;">
            <p>套餐设置:</p>
            <div style="display:flex;flex-wrap:wrap;width:630px">
                <el-row :gutter="20">
                    <el-col :span="8" v-for="(v,i) in list" :key="i">
                        <div>
                            <el-input style="width:80px;marginRight:10px;marginBottom:10px" v-model="v.num" maxlength="5"
                                placeholder="请输入档位" @input="v.num=v.num.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'').replace('.','').replace(/\./g,'').replace('$#$','').replace(/\.{2,}/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2')"></el-input>
                            <span style="120px">元({{v.num*(form.value - 0)}}豆)</span>
                        </div>
                    </el-col>
                </el-row>
                <!-- <div v-for="(v,i) in list" :key="i">
                    <el-input style="width:100px;marginRight:10px;marginBottom:10px" v-model="v.num" maxlength="5"
                        placeholder="请输入档位" @input="v.num=v.num.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'').replace('.','').replace(/\./g,'').replace('$#$','').replace(/\.{2,}/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2')"></el-input>
                    <span style="100px">元({{v.num*(form.value - 0)}}豆)</span>
                </div> -->
            </div>
            <div style="textAlign:right">
                <el-button type="primary" @click="handleSave2">保存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import Tinymce from "@/components/Tinymce";
import request from "@/utils/request";

export default {
  components: {
    Tinymce
  },
  data() {
    return {
      form: {
        id: "",
        key: "bean_price",
        type: 4,
        value: ""
      },
      form2: {
        id: "",
        key: "bean_package",
        type: 9,
        value: []
      },
      //   修改的数组
      list: [
        { num: "" },
        { num: "" },
        { num: "" },
        { num: "" },
        { num: "" },
        { num: "" }
      ]
    };
  },
  created() {
    this.getSetting();
    this.getSetting2();
  },
  methods: {
    getSetting() {
      request({
        url: "/api/backend/global/details",
        method: "get",
        params: { type: 4 }
      }).then(response => {
        if (response.data) {
          this.form = response.data;
        }
      });
    },
    getSetting2() {
      request({
        url: "/api/backend/global/details",
        method: "get",
        params: { type: 9 }
      }).then(response => {
        if (response.data) {
          this.form2 = response.data;
          this.form2.value.forEach((v, i) => {
            this.list[i].num = v;
          });
        }
      });
    },
    handleClear() {},
    handleSave() {
      this.$confirm("确定要保存, 是否继续?", "提示", {
        type: "warning"
      })
        .then(() => {
          request({
            url: "/api/backend/global/store",
            method: "post",
            data: this.form
          }).then(() => {
            this.getSetting();
            this.$message({
              type: "success",
              message: "操作成功"
            });
          });
        })
        .catch(() => {});
    },
    handleSave2() {
        console.log(this.list.every((v)=>{return v.num != ''}))
        if(!this.list.every((v)=>{return v.num != ''})) {
            this.$message({
              type: "error",
              message: "请完善套餐设置"
            });
            return
        }
      this.form2.value = [];
      this.list.forEach(v => {
        this.form2.value.push(v.num);
      });
      this.$confirm("确定要保存, 是否继续?", "提示", {
        type: "warning"
      })
        .then(() => {
          request({
            url: "/api/backend/global/store",
            method: "post",
            data: this.form2
          }).then(() => {
            this.getSetting();
            this.$message({
              type: "success",
              message: "操作成功"
            });
          });
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped>
.richtext-container {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
}
</style>

