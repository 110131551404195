<template>
  <div class="app-container">
    <div class="userHeader">
      <el-button size="small" type="primary" @click="handleAdd">新增赠送</el-button>
      用户昵称：
      <el-input
        v-model="listQuery.nickname"
        placeholder="请输入用户昵称"
        :clearable="true"
        style="width: 200px;margin: 0px 10px;"
      ></el-input>
      电话：
      <el-input
        v-model="listQuery.phone"
        placeholder="请输入电话"
        :clearable="true"
        style="width: 200px;margin: 0px 10px;"
      ></el-input>
      <el-select
        v-model="listQuery.status"
        placeholder="状态"
        :clearable="true"
      >
        <el-option label="待使用" value="1"></el-option>
        <el-option label="已使用" value="2"></el-option>
        <el-option label="已过期" value="3"></el-option>
      </el-select>
      <el-select
        v-model="listQuery.get_type"
        placeholder="领取方式"
        :clearable="true"
      >
        <el-option label="平台赠送" value="1"></el-option>
        <el-option label="手动领取" value="2"></el-option>
      </el-select>
      <el-button size="small" type="primary" @click="handleSearch">搜索</el-button>
    </div>
    <div style="margin-top:10px;text-align:right">
      <el-button size="small" type="danger" @click="handleDelAll">一键删除全部人优惠券</el-button>
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      style="margin-top: 15px;"
    >
      <el-table-column align="center" label="序号" width="95">
        <template slot-scope="scope">{{ scope.$index+1 }}</template>
      </el-table-column>
      <el-table-column align="center" label="头像" min-width="150">
        <template slot-scope="scope">
          <img
            v-if="scope.row.user.avatar != ''"
            :src="scope.row.user.avatar"
            alt=""
            style="width: 110px;height:110px;"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" label="昵称" min-width="110">
        <template slot-scope="scope">{{ scope.row.user.nickname }}</template>
      </el-table-column>
      <el-table-column align="center" label="联系电话" min-width="150">
        <template slot-scope="scope">{{ scope.row.user.phone }}</template>
      </el-table-column>
      <el-table-column align="center" label="领取时间" min-width="95">
        <template slot-scope="scope">
          {{scope.row.created_at}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态" min-width="95">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.status == 1">待使用</el-tag>
          <el-tag type="success" v-else-if="scope.row.status == 2">已使用</el-tag>
          <el-tag type="info" v-else>已过期</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="领取方式" min-width="95">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.get_type == 2">手动领取</el-tag>
          <el-tag type="success" v-else>平台赠送</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" min-width="100">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="primary"
            @click="handleDel(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      userCount: 0,
      listLoading: true,
      total: null,
      showFansRanking: false,
      listQuery: {
        id:'',
        page: 1,
        limit: 10,
        nickname:'',
        phone:'',
        status:'',
        get_type:'',
      },
      list: [],
    };
  },
  created() {
    this.listQuery.id = this.$route.query.id
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true
      request({
        url: "/api/backend/coupon/userCoupon",
        method: "get",
        params: this.listQuery
      })
        .then(result => {
          this.list = result.data.data;
          this.total = result.data.total;
          this.listLoading = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    handleAdd() {
      this.$router.push('/operate/canReceiveList?id='+this.$route.query.id)
    },
    handleSearch() {
      this.listQuery.page = 1
      this.getList();
    },
    handleShowInfo(row) {
      this.userInfo = row;
      this.showUserInfo = true;
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleDel(row) {
      this.$confirm(`此操作将删除用户优惠券，是否继续？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
      .then(result => {
        request({
          url: '/api/backend/coupon/deleteCoupon',
          method: 'post',
          data: {id:row.id}
        }).then(response => {
          this.showDialog = false
          this.$notify({
            title: '成功',
            message: '赠送成功',
            type: 'success',
            duration: 2000
          })
          this.getList()
        }).catch((err) => {
          this.btnLoading = false
        })
      })
      .catch(err => {
        this.$notify.info("已取消");
      })
    },
    handleDelAll() {
      this.$confirm(`此操作将删除全部优惠券(未使用)，是否继续？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
      .then(result => {
        request({
          url: '/api/backend/coupon/deleteCouponAll',
          method: 'post',
          data: {coupon_id:this.listQuery.id}
        }).then(response => {
          this.showDialog = false
          this.$notify({
            title: '成功',
            message: '赠送成功',
            type: 'success',
            duration: 2000
          })
          this.getList()
        }).catch((err) => {
          this.btnLoading = false
        })
      })
      .catch(err => {
        this.$notify.info("已取消");
      })
    },
  }
};
</script>
<style scoped>
.userHeader .userNumber {
  margin: 25px 0px;
}
</style>
