<template>
  <div class="app-container">
    <div class="userHeader">
      <el-input
        v-model="listQuery.keyword"
        placeholder="请输入用户昵称/电话"
        :clearable="true"
        style="width: 200px;margin: 0px 10px;"
      ></el-input>
      <el-select
        v-model="listQuery.is_receive"
        placeholder="是否领取"
        :clearable="true"
      >
        <el-option label="已领取" value="1"></el-option>
        <el-option label="未领取" value="0"></el-option>
      </el-select>
      <el-button size="small" type="primary" @click="handleSearch">搜索</el-button>
      <el-button size="small" type="success" @click="handleSend('more')">一键赠送多个用户优惠券</el-button>
      <!-- <el-button size="small" type="danger" @click="handleSend('all')">一键赠送全部用户优惠券</el-button> -->
      <el-button size="small" type="danger" @click="handleSubmitAll('all')">一键赠送全部用户优惠券</el-button>
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      style="margin-top: 15px;"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column align="center" label="序号" width="95">
        <template slot-scope="scope">{{ scope.$index+1 }}</template>
      </el-table-column>
      <el-table-column align="center" label="头像" min-width="150">
        <template slot-scope="scope">
          <img
            v-if="scope.row.avatar != ''"
            :src="scope.row.avatar"
            alt=""
            style="width: 110px;height: 110px;"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" label="昵称" min-width="110">
        <template slot-scope="scope">{{ scope.row.nickname }}</template>
      </el-table-column>
      <el-table-column align="center" label="联系电话" min-width="150">
        <template slot-scope="scope">{{ scope.row.phone }}</template>
      </el-table-column>
      <!-- <el-table-column align="center" label="领取时间" min-width="95">
        <template slot-scope="scope">
          {{scope.row.created_at}}
        </template>
      </el-table-column> -->
      <el-table-column align="center" label="性别" min-width="95">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.gender == 1">男</el-tag>
          <el-tag type="danger"  v-else-if="scope.row.gender == 2">女</el-tag>
          <el-tag type="info"    v-else>未设置</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="是否领取" min-width="95">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.receive">是</el-tag>
          <el-tag type="info" v-else>否</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="拥有数量" min-width="95">
        <template slot-scope="scope">
          {{scope.row.couponCount}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" min-width="95">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="primary"
            @click="handleSend(scope.row)"
            >赠送</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog title="赠送优惠券" :visible.sync="showDialog">
      <el-form label-width="250px">
        <el-form-item label="请输入赠送数量">
          <el-input type="number" style="width: 150px" v-model="count"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="showDialog = false">取消</el-button>
          <el-button size="small" v-if="sendType == 'all'" type="success" @click="handleSubmitAll">确认</el-button>
          <el-button size="small" v-else type="primary" @click="handleSubmit">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      listLoading: true,
      total: null,
      showFansRanking: false,
      listQuery: {
        page: 1,
        limit: 10,
        coupon_id :'',
        keyword:'',
        is_receive:'',
      },
      list: [],
      showDialog:false,
      count: 1,
      sendUser:[],
      ids:[],
      oneIds:[],
      sendType:'',
    };
  },
  created() {
    this.listQuery.coupon_id  = this.$route.query.id
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true
      request({
        url: "/api/backend/coupon/couponUser",
        method: "get",
        params: this.listQuery
      })
        .then(result => {
          this.list = result.data.data;
          this.userCount = result.data.user_count;
          this.total = result.data.total;
          this.listLoading = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    handleSelectionChange(val) {
      this.ids = val
    },
    resetSends() {
      this.sendUser = [{
        user_id:'',
        num:'',
      }]
    },
    handleSend(row) {
      this.resetSends()
      if(row.id) {
        this.oneIds.push(row)
        this.sendType = 'sendOne'
      }else {
        if(this.ids.length == 0 && row == 'more') {
          this.$message({
            type: "error",
            message: `请选择赠送用户`
          });
          return
        }
        this.sendUser = []
        this.sendType = row
      }
      this.showDialog = true
      this.count = 1
    },
    handleSubmit() {
      if(this.count <= 0) {
        this.$message({
          type: "error",
          message: `最少赠送一张`
        });
        return
      }
      let text = ''
      if(this.sendType == 'sendOne') {
        text = '该用户'
      }else {
        text = '多个用户'
      }
      this.$confirm(`此操作将赠送${text}优惠券，是否继续？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
      .then(result => {
        if(this.sendType == 'sendOne') {
          this.sendUser = this.oneIds.map(item=> {
            return {user_id:item.id,num: this.count}
          })
        }else {
          this.sendUser = this.ids.map(item=> {
            return {user_id:item.id,num: this.count}
          })
        }
        request({
          url: '/api/backend/coupon/sendCoupon',
          method: 'post',
          data: {
            sendUser:this.sendUser,
            coupon_id: this.listQuery.coupon_id,
          }
        }).then(response => {
          this.showDialog = false
          this.$notify({
            title: '成功',
            message: '赠送成功',
            type: 'success',
            duration: 2000
          })
          this.oneIds = []
          this.ids = []
          this.sendUser = []
          this.getList()
        }).catch((err) => {
          this.btnLoading = false
        })
      })
      .catch(err => {
        this.$notify.info("已取消");
      })
    },
    handleSubmitAll() {
      // if(this.count <= 0) {
      //   this.$message({
      //     type: "error",
      //     message: `最少赠送一张`
      //   });
      //   return
      // }
      this.$confirm(`此操作将赠送全部用户此优惠券，是否继续？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
      .then(result => {
        request({
          url: '/api/backend/coupon/sendCouponAll',
          method: 'post',
          data: {
            coupon_id: this.listQuery.coupon_id,
          }
        }).then(response => {
          this.showDialog = false
          this.$notify({
            title: '成功',
            message: '赠送成功',
            type: 'success',
            duration: 2000
          })
          this.getList()
        }).catch((err) => {
          this.btnLoading = false
        })
      })
      .catch(err => {
        this.$notify.info("已取消");
      })
    },
    handleSearch() {
      this.listQuery.page = 1
      this.getList();
    },
    handleShowInfo(row) {
      this.userInfo = row;
      this.showUserInfo = true;
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
  }
};
</script>
<style scoped>
.userHeader .userNumber {
  margin: 25px 0px;
}
</style>
