<template>
  <div class="app-container">
    <div class="userHeader">
      用户昵称：
      <el-input
        v-model="listQuery.nickname"
        placeholder="请输入用户昵称"
        :clearable="true"
        style="width: 200px;margin: 0px 10px;"
      ></el-input>
      电话：
      <el-input
        v-model="listQuery.phone"
        placeholder="请输入电话"
        :clearable="true"
        style="width: 200px;margin: 0px 10px;"
      ></el-input>
      时间:
      <el-date-picker
      v-model="listQuery.start_datetime"
      type="datetime"
      placeholder="请选择开始时间"
      value-format="yyyy-MM-dd HH:mm:ss"
      ></el-date-picker>
      <el-date-picker
      v-model="listQuery.end_datetime"
      type="datetime"
      placeholder="请选择结束时间"
      value-format="yyyy-MM-dd HH:mm:ss"
      ></el-date-picker>
      <!-- <el-select
        v-model="listQuery.status"
        placeholder="状态"
        :clearable="true"
      >
        <el-option label="待使用" value="1"></el-option>
        <el-option label="已使用" value="2"></el-option>
        <el-option label="已过期" value="3"></el-option>
      </el-select>
      <el-select
        v-model="listQuery.get_type"
        placeholder="领取方式"
        :clearable="true"
      >
        <el-option label="平台赠送" value="1"></el-option>
        <el-option label="手动领取" value="2"></el-option>
      </el-select> -->
      <el-button size="small" type="primary" @click="handleSearch">搜索</el-button>
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      style="margin-top: 15px;"
    >
      <el-table-column align="center" label="序号" width="95">
        <template slot-scope="scope">{{ scope.$index+1 }}</template>
      </el-table-column>
      <el-table-column align="center" label="头像" min-width="150">
        <template slot-scope="scope">
          <img
            v-if="scope.row.user.avatar != ''"
            :src="scope.row.user.avatar"
            alt=""
            style="width: 110px;height:110px;"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" label="昵称" min-width="110">
        <template slot-scope="scope">{{ scope.row.user.nickname }}</template>
      </el-table-column>
      <el-table-column align="center" label="联系电话" min-width="150">
        <template slot-scope="scope">{{ scope.row.user.phone }}</template>
      </el-table-column>
      <el-table-column align="center" label="使用时间" min-width="95">
        <template slot-scope="scope">
          {{scope.row.use_datetime}}
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" label="状态" min-width="95">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.status == 1">待使用</el-tag>
          <el-tag type="success" v-else-if="scope.row.status == 2">已使用</el-tag>
          <el-tag type="info" v-else>已过期</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="领取方式" min-width="95">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.is_certification == 2">手动领取</el-tag>
          <el-tag type="success" v-else>平台赠送</el-tag>
        </template>
      </el-table-column> -->
      <!-- <el-table-column align="center" label="操作" min-width="400">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="primary"
            @click="handleDel(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>

    <div class="pagination">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      userCount: 0,
      listLoading: true,
      total: null,
      showFansRanking: false,
      listQuery: {
        id:'',
        page: 1,
        limit: 10,
        nickname:'',
        phone:'',
        start_datetime:'',
        end_datetime:'',
      },
      list: [],
    };
  },
  created() {
    this.listQuery.id = this.$route.query.id
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true
      request({
        url: "/api/backend/coupon/userCoupon",
        method: "get",
        params: this.listQuery
      })
        .then(result => {
          this.list = result.data.data;
          this.total = result.data.total;
          this.listLoading = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    handleAdd() {
      this.$router.push('/operate/canReceiveList')
    },
    handleSearch() {
      this.listQuery.page = 1
      this.getList();
    },
    handleShowInfo(row) {
      this.userInfo = row;
      this.showUserInfo = true;
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleDel(row) {

    },
    handleDelAll() {

    },
  }
};
</script>
<style scoped>
.userHeader .userNumber {
  margin: 25px 0px;
}
</style>
