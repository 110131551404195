<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate"
        >新增</el-button
      >
      <el-input
        v-model="listQuery.name"
        placeholder="请输入公司名称"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.is_on"
        placeholder="状态"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="正常" :value="1" />
        <el-option label="已下架" :value="0" />
      </el-select>
      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="公司logo" min-width="110" align="center">
        <template slot-scope="scope">
          <!-- <img v-if="scope.row.logo" :src="scope.row.logo" class="image-url" /> -->
          <el-image class="image-url" :src="scope.row.logo" lazy fit="contain" :preview-src-list="[scope.row.logo]"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="公司封面" min-width="110" align="center">
        <template slot-scope="scope">
          <!-- <img v-if="scope.row.cover" :src="scope.row.cover" class="image-url"/> -->
          <el-image class="image-url-150" :src="scope.row.cover" fit="contain" lazy :preview-src-list="[scope.row.cover]"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="公司名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="所在地" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.province ? scope.row.province.name : "" }}
          {{ scope.row.city ? scope.row.city.name : "" }}
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_on == 1" type="success">正常</el-tag>
          <el-tag v-else type="danger">已下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="210"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)"
            >修改</el-button
          >

          <el-button size="mini" type="danger" @click="handleDel(scope.row.id)"
            >删除</el-button
          >

          <el-button
            v-if="scope.row.is_on == 1"
            size="mini"
            type="danger"
            @click="handleStatus(scope.row.id, '下架')"
            >下架</el-button
          >
          <el-button
            v-else
            size="mini"
            type="success"
            @click="handleStatus(scope.row.id, '上架')"
            >上架</el-button
          >

          <el-button size="mini" type="primary" @click="posts(scope.row)"
            >职位管理</el-button
          >

          <el-button size="mini" type="primary" @click="resumes(scope.row)"
            >求职管理</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="150px">
        <el-form-item label="公司名称" prop="name">
          <el-input type="text" v-model="form.name" clearable />
        </el-form-item>
        <el-form-item label="所在地" prop="">
          <el-select
            v-model="form.province_id"
            placeholder="省"
            @change="getReCities()"
            @clear="closeReCities"
            clearable
            style="width: 150px"
          >
            <el-option
              v-for="item in provinces"
              :key="item.province_id"
              :label="item.name"
              :value="item.province_id"
            ></el-option>
          </el-select>

          <el-select
            v-model="form.city_id"
            placeholder="市"
            @clear="closeReDistricts"
            clearable
            style="width: 150px; margin-left: 10px"
          >
            <el-option
              v-for="item in cities"
              :key="item.city_id"
              :label="item.name"
              :value="item.city_id"
            ></el-option>
          </el-select>

          <!--          <el-select v-model="form.district_id" placeholder="区" clearable style="width: 150px;margin-left: 10px;">-->
          <!--            <el-option v-for="item in districts" :key="item.district_id" :label="item.name" :value="item.district_id"></el-option>-->
          <!--          </el-select>-->
        </el-form-item>
        <el-form-item label="公司logo(148*148)" prop="logo">
          <upload-one v-model="form.logo" style="width:148px;height:148px"></upload-one>
        </el-form-item>
        <el-form-item label="公司封面(750*600)" prop="cover">
          <upload-one v-model="form.cover" style="width:375px;height:300px"></upload-one>
        </el-form-item>
        <el-form-item label="权重" prop="weight">
          <el-input-number
            v-model="form.weight"
            :min="1"
            :max="9999"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="公司详细介绍" prop="introduce">
          <el-input type="textarea" :rows="4" v-model="form.introduce" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import Tinymce from "@/components/Tinymce";

export default {
  components: {
    Tinymce,
  },
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        name: "",
        is_on: "",
      },
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "修改",
        create: "新增",
      },
      form: {
        id: "",
        name: "",
        logo: "",
        cover: "",
        province_id: "",
        city_id: "",
        weight: 1,
        introduce: "",
      },
      rules: {
        name: [
          { required: true, message: "公司名称不能为空", trigger: "change" },
        ],
        logo: [
          { required: true, message: "公司logo不能为空", trigger: "change" },
        ],
        cover: [
          { required: true, message: "公司封面不能为空", trigger: "change" },
        ],
        weight: [
          { required: true, message: "权重不能为空", trigger: "change" },
        ],
        introduce: [
          {
            required: true,
            message: "公司详细介绍不能为空",
            trigger: "change",
          },
        ],
      },
      btnLoading: false,
      provinces: [],
      cities: [],
      districts: [],
    };
  },
  created() {
    this.getList();
    this.getProvince();
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/company/list",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    resetForm() {
      this.form = {
        id: "",
        name: "",
        logo: "",
        cover: "",
        province_id: "",
        city_id: "",
        weight: 1,
        introduce: "",
      };
      this.btnLoading = false;
    },
    handleCreate() {
      this.resetForm();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      if (this.$refs.tinymce) this.$refs.tinymce.setContent("");
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    handleUpdate(row) {
      this.resetForm();
      this.form = Object.assign({}, row);
      this.dialogStatus = "update";
      this.getReCities(this.form.city_id);
      this.dialogFormVisible = true;
      if (this.$refs.tinymce) this.$refs.tinymce.setContent(row.introduce);
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    saveData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (!this.form.province_id || !this.form.city_id) {
            this.$message.warning("所在地不能为空");
            return;
          }

          this.btnLoading = true;
          request({
            url: "/api/backend/company/store",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    handleDel(val) {
      this.$confirm("确定要删除, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/company/del",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    // 获取省
    getProvince() {
      return request({
        url: "/api/common/area/index",
        method: "get",
      }).then((res) => {
        console.log(res);
        this.provinces = res.data;
      });
    },
    // 点击清空省
    closeReCities(city_id) {
      this.cities = [];
      this.form.city_id = "";
      this.districts = [];
      this.form.district_id = "";
    },
    // 获取市
    getReCities() {
      if (this.form.province_id === "") {
        return;
      }
      return request({
        url: "/api/common/area/cities",
        method: "get",
        params: {
          province_id: this.form.province_id,
        },
      }).then((res) => {
        this.cities = res.data;
        // if (city_id) {
        //   this.form.city_id = city_id;
        // } else {
        //   this.form.city_id = "";
        // }

        this.districts = [];
        this.form.district_id = "";
      });
    },
    // 点击清空市
    closeReDistricts() {
      this.form.district_id = "";
      this.districts = [];
    },
    // 获取区
    getReDistricts() {
      if (this.form.city_id === "") {
        return;
      }
      return request({
        url: "/api/common/area/districts",
        method: "get",
        params: {
          city_id: this.form.city_id,
        },
      }).then((res) => {
        this.districts = res.data;
        this.form.district_id = "";
      });
    },
    //上下架
    handleStatus(val, text) {
      this.$confirm("此操作将" + text + "该公司, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/company/upDown",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    //职位管理
    posts(row) {
      this.$router.push(`/recruit/posts?company_id=${row.id}`);
    },
    //求职管理
    resumes(row) {
      this.$router.push(`/recruit/resumes?company_id=${row.id}`);
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
