<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate"
        >新增</el-button
      >
      <el-input
        v-model="listQuery.company_name"
        placeholder="请输入公司名称"
        style="width: 200px"
        class="filter-item"
        clearable
        v-if="!company_id"
      />
      <el-input
        v-model="listQuery.post_name"
        placeholder="请输入职位名称"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.is_on"
        placeholder="状态"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="正常" :value="1" />
        <el-option label="已下架" :value="0" />
      </el-select>
      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="ID" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="公司名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.company ? scope.row.company.name : "" }}
        </template>
      </el-table-column>
      <el-table-column label="职位名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="职位类型" min-width="150" align="center">
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.post_category_parent"
            type="primary"
            style="margin: 3px"
            >{{ scope.row.post_category_parent.name }}</el-tag
          >
          <el-tag
            v-if="scope.row.post_category"
            type="success"
            style="margin: 3px"
            >{{ scope.row.post_category.name }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="招聘人数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.num }}
        </template>
      </el-table-column>
      <el-table-column label="学历要求" min-width="110" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.education_requirements == 1">不限</span>
          <span v-else-if="scope.row.education_requirements == 2"
            >初中及以下</span
          >
          <span v-else-if="scope.row.education_requirements == 3"
            >中专/中技</span
          >
          <span v-else-if="scope.row.education_requirements == 4">高中</span>
          <span v-else-if="scope.row.education_requirements == 5">大专</span>
          <span v-else-if="scope.row.education_requirements == 6">本科</span>
          <span v-else-if="scope.row.education_requirements == 7">硕士</span>
          <span v-else-if="scope.row.education_requirements == 8">博士</span>
        </template>
      </el-table-column>
      <el-table-column label="所在地" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.province ? scope.row.province.name : "" }}
          {{ scope.row.city ? scope.row.city.name : "" }}
        </template>
      </el-table-column>
      <el-table-column label="薪资" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.salary }}
        </template>
      </el-table-column>
      <el-table-column label="简历数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.resume_num }}
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_on == 1" type="success">正常</el-tag>
          <el-tag v-else type="danger">已下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)"
            >修改</el-button
          >

          <el-button
            v-if="scope.row.is_on == 1"
            size="mini"
            type="danger"
            @click="handleStatus(scope.row.id, '下架')"
            >下架</el-button
          >
          <el-button
            v-else
            size="mini"
            type="success"
            @click="handleStatus(scope.row.id, '上架')"
            >上架</el-button
          >

          <el-button size="mini" type="primary" @click="resumes(scope.row)"
            >查看简历</el-button
          >

          <el-button size="mini" type="danger" @click="handleDel(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="80px">
        <el-form-item label="公司" prop="company_id">
          <el-select v-model="form.company_id" clearable>
            <el-option
              v-for="(item, index) in companies"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职位名称" prop="name">
          <el-input type="text" v-model="form.name" clearable />
        </el-form-item>
        <el-form-item label="招聘人数" prop="num">
          <el-input-number
            v-model="form.num"
            :min="1"
            :max="9999"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="薪资" prop="salary">
          <el-input
            type="text"
            v-model="form.salary"
            clearable
            placeholder="例子：10-14k"
          />
        </el-form-item>
        <el-form-item label="工作经验" prop="salary">
          <el-input
            type="text"
            v-model="form.work_time"
            clearable
            placeholder="例子：1-3年"
          />
        </el-form-item>
        <el-form-item label="学历要求" prop="education_requirements">
          <el-select v-model="form.education_requirements" clearable>
            <el-option label="不限" :value="1" />
            <el-option label="初中及以下" :value="2" />
            <el-option label="中专/中技" :value="3" />
            <el-option label="高中" :value="4" />
            <el-option label="大专" :value="5" />
            <el-option label="本科" :value="6" />
            <el-option label="硕士" :value="7" />
            <el-option label="博士" :value="8" />
          </el-select>
        </el-form-item>
        <el-form-item label="所在地" prop="">
          <el-select
            v-model="form.province_id"
            placeholder="省"
            @change="getReCities()"
            @clear="closeReCities"
            clearable
            style="width: 150px"
          >
            <el-option
              v-for="item in provinces"
              :key="item.province_id"
              :label="item.name"
              :value="item.province_id - 0"
            ></el-option>
          </el-select>

          <el-select
            v-model="form.city_id"
            placeholder="市"
            @clear="closeReDistricts"
            clearable
            style="width: 150px; margin-left: 10px"
          >
            <el-option
              v-for="item in cities"
              :key="item.city_id"
              :label="item.name"
              :value="item.city_id - 0"
            ></el-option>
          </el-select>

          <!--          <el-select v-model="form.district_id" placeholder="区" clearable style="width: 150px;margin-left: 10px;">-->
          <!--            <el-option v-for="item in districts" :key="item.district_id" :label="item.name" :value="item.district_id"></el-option>-->
          <!--          </el-select>-->
        </el-form-item>
        <el-form-item label="职位类型" prop="">
          <el-select
            v-model="form.post_category_parent_id"
            placeholder="一级类型"
            @change="getSecondPostCategories()"
            @clear="closeSecondPostCategories"
            clearable
            style="width: 150px"
          >
            <el-option
              v-for="(item, index) in firstPostCategories"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>

          <el-select
            v-model="form.post_category_id"
            placeholder="二级类型"
            clearable
            style="width: 150px; margin-left: 10px"
          >
            <el-option
              v-for="(item, index) in secondPostCategories"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="岗位职能" prop="post_function">
          <el-input type="textarea" :rows="4" v-model="form.post_function" />
        </el-form-item>
        <el-form-item label="任职要求" prop="job_requirements">
          <el-input type="textarea" :rows="4" v-model="form.job_requirements" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        company_id: "",
        company_name: "",
        post_name: "",
        is_on: "",
      },
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "修改",
        create: "新增",
      },
      form: {
        id: "",
        company_id: this.company_id || "",
        name: "",
        num: 1,
        salary: "",
        education_requirements: 1,
        province_id: "",
        city_id: "",
        post_category_parent_id: "",
        post_category_id: "",
        post_function: "",
        job_requirements: "",
        work_time: "",
      },
      rules: {
        company_id: [
          { required: true, message: "公司不能为空", trigger: "change" },
        ],
        name: [
          { required: true, message: "职位名称不能为空", trigger: "change" },
        ],
        num: [
          { required: true, message: "招聘人数不能为空", trigger: "change" },
        ],
        salary: [
          { required: true, message: "薪资不能为空", trigger: "change" },
        ],
        education_requirements: [
          { required: true, message: "学历要求不能为空", trigger: "change" },
        ],
        post_function: [
          { required: true, message: "岗位职能不能为空", trigger: "change" },
        ],
        job_requirements: [
          { required: true, message: "任职要求不能为空", trigger: "change" },
        ],
      },
      btnLoading: false,
      company_id: "",
      companies: [],
      provinces: [],
      cities: [],
      districts: [],
      firstPostCategories: [],
      secondPostCategories: [],
    };
  },
  created() {
    this.company_id = this.listQuery.company_id =
      this.$route.query.company_id - 0;
    if (!this.listQuery.company_id) {
      this.listQuery.company_id = "";
    }
    this.getList();
    this.getCompanies();
    this.getProvince();
    this.getFirstPostCategories();
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/company/postList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    resetForm() {
      this.form = {
        id: "",
        company_id: this.company_id || "",
        name: "",
        num: 1,
        salary: "",
        education_requirements: 1,
        province_id: "",
        city_id: "",
        post_category_parent_id: "",
        post_category_id: "",
        post_function: "",
        job_requirements: "",
      };
      this.btnLoading = false;
    },
    handleCreate() {
      this.resetForm();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    handleUpdate(row) {
      this.resetForm();
      this.form = Object.assign({}, row);
      this.dialogStatus = "update";
      this.getSecondPostCategories(this.form.post_category_id);
      this.getReCities(1);
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },

    saveData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (!this.form.province_id || !this.form.city_id) {
            this.$message.warning("所在地不能为空");
            return;
          }
          if (
            !this.form.post_category_parent_id ||
            !this.form.post_category_id
          ) {
            this.$message.warning("职位类型不能为空");
            return;
          }
          // console.log(this.form)
          // return;

          this.btnLoading = true;
          request({
            url: "/api/backend/company/postStore",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    handleDel(val) {
      this.$confirm("确定要删除, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/company/postDel",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    //获取公司
    getCompanies() {
      request({
        url: "/api/backend/company/list",
        method: "get",
        params: {
          page: 1,
          limit: 100000,
          name: "",
          is_on: 1,
        },
      }).then((response) => {
        this.companies = response.data.data;
      });
    },
    // 获取省
    getProvince() {
      return request({
        url: "/api/common/area/index",
        method: "get",
      }).then((res) => {
        console.log(res);
        this.provinces = res.data;
      });
    },
    // 点击清空省
    closeReCities() {
      this.cities = [];
      this.form.city_id = "";
      this.districts = [];
      this.form.district_id = "";
    },
    // 获取市
    getReCities(type) {
      if (this.form.province_id === "") {
        return;
      }
      return request({
        url: "/api/common/area/cities",
        method: "get",
        params: {
          province_id: this.form.province_id,
        },
      }).then((res) => {
        this.cities = res.data;
        this.form.city_id = type ? this.form.city_id : "";
        this.districts = [];
        this.form.district_id = "";
      });
    },
    // 点击清空市
    closeReDistricts() {
      this.form.district_id = "";
      this.districts = [];
    },
    // 获取区
    getReDistricts(type) {
      if (this.form.city_id === "") {
        return;
      }
      return request({
        url: "/api/common/area/districts",
        method: "get",
        params: {
          city_id: this.form.city_id,
        },
      }).then((res) => {
        this.districts = res.data;
        this.form.district_id = "";
      });
    },
    //获取一级岗位分类
    getFirstPostCategories() {
      request({
        url: "/api/backend/company/postCategoryList",
        method: "get",
        params: {
          page: 1,
          limit: 100000,
          is_on: 1,
          type: 1,
          name: "",
        },
      }).then((response) => {
        this.firstPostCategories = response.data.data;
      });
    },
    // 点击清空一级岗位分类
    closeSecondPostCategories() {
      this.secondPostCategories = [];
      this.form.post_category_id = "";
    },
    //获取二级岗位分类
    getSecondPostCategories(post_category_id) {
      if (this.form.post_category_parent_id === "") {
        return;
      }
      request({
        url: "/api/backend/company/postCategoryList",
        method: "get",
        params: {
          page: 1,
          limit: 100000,
          is_on: 1,
          type: 2,
          name: "",
          parent_id: this.form.post_category_parent_id,
        },
      }).then((response) => {
        this.secondPostCategories = response.data.data;
        this.form.post_category_id = post_category_id ? post_category_id : "";
      });
    },
    //上下架
    handleStatus(val, text) {
      this.$confirm("此操作将" + text + "该职位, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/company/postUpDown",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    //查看简历
    resumes(row) {
      this.$router.push(`/recruit/resumes?post_id=${row.id}`);
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
