<template>
  <div class="app-container">
    <h3>{{teacher_name}}老师</h3>

    <el-form ref="form" :rules="rules" :model="form" label-width="150px">
      <el-form-item label="请安排教师任课学校">
        <div class="box">
          <div class="tabs" v-for="(i, key) in schoolList" :key="key">
            {{ i.name }}
            <i class="el-icon-circle-close" @click="delet(key)"></i>
          </div>
        </div>
        <div class="btnBox">
          <el-select
            v-model="SchoolName"
            placeholder="请选择学校"
            @change="change"
          >
            <el-option
              v-for="(item, key) in options"
              :key="key"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-button type="primary" style="margin-left: 10px" @click="addSchool"
            >添加</el-button
          >
        </div>
      </el-form-item>
      <el-form-item>
        <el-button  @click="$router.go(-1)">返回</el-button>
        <el-button type="primary" @click="submit" :loading="btnLoading">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      schoolList: [],
      form: {},
      school_id: null,
      teacher_id: null,
      options: [],
      value: "",
      SchoolName: "",
      btnLoading:false,
      rules:{
        
      }
    };
  },
  created() {
    this.teacher_id = this.$route.query.id;
    this.teacher_name= this.$route.query.name;
    this.getSchoolList();
    this.distributionDetail();
  },
  mounted() {},
  methods: {
    addSchool() {
      this.schoolList.push({
        id: null,
        teacher_id: this.teacher_id,
        school_id: this.school_id,
        name: this.SchoolName,
      });
    },
    delet(key) {
      console.log(key);
      this.schoolList.splice(key, 1);
    },
    change(e) {
      console.log(111111111);
      console.log(e);
      //e.value
      this.SchoolName = e.name;
      this.school_id = e.id;
    },
    distributionDetail() {
      request({
        url: "/api/backend/school/distributionDetail",
        method: "get",
        params: {
          teacher_id: this.teacher_id,
        },
      }).then((response) => {
        if (response.data) {
          response.data.forEach((e) => {
            this.schoolList.push({
              id: e.id,
              teacher_id: e.teacher_id,
              school_id: e.school_id,
              name: e.school.name,
            });
          });
        }
        console.log(this.schoolList);
      });
    },
    getSchoolList() {
      request({
        url: "/api/backend/school/list",
        method: "get",
        params: {
          page: 1,
          limit: 10,
          name: "",
        },
      }).then((response) => {
        this.options = response.data.data;
      });
    },
    submit() {
      this.btnLoading=true
      if (this.schoolList.length==0) {
         this.$message({
          showClose: true,
          message: '分配学校不能为空',
          type: 'error'
        });
        this.btnLoading=false
        return
      }
      var distribution = this.schoolList;
      request({
        url: "/api/backend/school/distributionStore",
        method: "post",
        data: { distribution },
      })
        .then((response) => {
          this.btnLoading = false;
          this.dialogFormVisible = false;
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.getList();
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  display: flex;
  flex-wrap: wrap;
  .tabs {
    position: relative;
    border: 1px solid #d3d3d3;
    padding: 0 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    .el-icon-circle-close {
      position: absolute;
      color: red;
      top: -9px;
      right: -9px;
      font-size: 18px;
    }
  }
}
.btnBox {
  margin-top: 20px;
}
.el-form-item {
  width: 100%;
}
// .btnBox {
//     border: 1px solid #d3d3d3;
//     padding: 10px;
// }
</style>