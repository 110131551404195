<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate(null)">新增</el-button>
      <el-input v-model="listQuery.name" placeholder="请输入课程名称" style="width: 200px;" class="filter-item" clearable />
      <el-button class="filter-item" type="primary" @click="handleFilter">搜索</el-button>
    </div>
    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" border fit highlight-current-row>
      <el-table-column label="ID" width="95" align="center">
        <template slot-scope="scope">
          <!-- {{ scope.$index + 1 }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="课程" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="价格" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.price }}
        </template>
      </el-table-column>
      <el-table-column label="课时" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.total_class_hour }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="280" align="center" class-name="small-padding fixed-width" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleCreate(scope.row.id)">修改</el-button>
          <!-- <el-button size="mini" type="danger" @click="initOne(scope.row.id)">初始化密码</el-button> -->
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[10,20,30, 50]" :page-size="listQuery.limit" :total="total" background
        layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

  </div>
</template>

<script>
import request from '@/utils/request'

export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        name: '',
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '修改',
        create: '新增',
      },
      form: {
        id: '',
        name: '',
        account: '',
      },
      rules: {
        name: [
          { required: true, message: '学校名称不能为空', trigger: 'change' },
        ],
        account: [
          { required: true, message: '账号不能为空', trigger: 'change' },
        ],
      },
      btnLoading: false,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.listLoading = true
      request({
        url: '/api/backend/otherCourse/list',
        method: 'get',
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data
        this.total = response.data.total
        this.listLoading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleSizeChange(val) {
      this.listQuery.limit = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.page = val
      this.getList()
    },
    resetForm() {
      this.form = {
        id: '',
        name: '',
        account: '',
      }
      this.btnLoading = false
    },
    handleCreate(id) {
        this.$router.push(`/E-sportsManagement/skillCourse_edit?id=${id}`);
    },
    handleUpdate(row) {
      this.resetForm()
      this.form = Object.assign({}, row)
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['form'].clearValidate()
      })
    },
    saveData() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.btnLoading = true
          request({
            url: '/api/backend/school/store',
            method: 'post',
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false
              this.dialogFormVisible = false
              this.$message({
                type: 'success',
                message: '操作成功',
              })
              this.getList()
            })
            .catch((err) => {
              this.btnLoading = false
            })
        }
      })
    },
    // 初始化
    initOne(id) {
      this.$confirm('此操作将初始化该账号密码, 是否继续?', '提示', {
        type: 'warning',
      })
        .then(() => {
          request({
            url: '',
            method: 'post',
            data: { id },
          }).then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '操作成功',
            })
          })
        })
        .catch(() => {})
    },
  },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
