<template>
  <div class="app-container">
    <el-form ref="form" :rules="rules" :model="form" label-width="150px">
      <el-form-item label="课程名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item label="概述" prop="summary">
        <el-input
          v-model="form.summary"
          type="textarea"
          :rows="4"
          clearable
          style="width: 500px; margin-bottom: 5px"
          placeholder="请输入内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="总课时" prop="total_class_hour">
        <el-input
          v-model="form.total_class_hour"
          placeholder="请输入内容"
          type='number'
        ></el-input>
      </el-form-item>
      <el-form-item label="价格" prop="price">
        <el-input v-model="form.price" placeholder="请输入内容" type='number'></el-input>
      </el-form-item>
      <el-form-item label="课程详情" prop="details">
        <tinymce :height="400" v-model="form.details" :value="form.details" />
      </el-form-item>
      <el-form-item>
        <el-button @click="$router.go(-1)">返回</el-button>
        <el-button type="primary" @click="submit" :loading="btnLoading"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import Tinymce from "@/components/Tinymce";
import request from "@/utils/request";
export default {
  components: {
    Tinymce,
  },
  data() {
    return {
      btnLoading: false,
      input: "",
      form: {
        id: null,
        name: "",
        summary: "",
        total_class_hour: "",
        price: "",
        details: "",
      },
      options: [],
      value: "",
      rules: {
        name: [
          { required: true, message: "课程名称不能为空", trigger: "blur" },
        ],
        summary: [{ required: true, message: "概述不能为空", trigger: "blur" }],
        total_class_hour: [
          { required: true, message: "总课时不能为空", trigger: "blur" },
        ],
        price: [{ required: true, message: "价格不能为空", trigger: "blur" }],
        details: [
          { required: true, message: "课程详情不能为空", trigger: "change" },
        ],
      },
    };
  },
  created() {
    if (this.$route.query.id !== "null") {
      this.form.id = this.$route.query.id;
      this.getDetail(this.form.id);
    }
  },
  mounted() {},
  methods: {
    delet(key) {
      console.log(key);
    },
    getDetail(val) {
      this.listLoading = true;
      request({
        url: "/api/backend/otherCourse/detail",
        method: "get",
        params: { id: val },
      }).then((response) => {
        this.form = response.data;
        this.listLoading = false;
      });
    },
    submit() {
      this.btnLoading = true;
      if (!this.form.details) {
        this.$message({
          showClose: true,
          message: "课程详情不能为空",
          type: "warning",
        });
        this.btnLoading = false;
        return;
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          request({
            url: "/api/backend/otherCourse/store",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  display: flex;
  flex-wrap: wrap;
  .tabs {
    position: relative;
    border: 1px solid #d3d3d3;
    padding: 0 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    .el-icon-circle-close {
      position: absolute;
      color: red;
      top: -9px;
      right: -9px;
      font-size: 18px;
    }
  }
}
.btnBox {
  margin-top: 20px;
}
// .el-form-item {
//   width: 100%;
// }
.el-input {
  width: 500px;
}
</style>