<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate">新增</el-button>
      <el-input v-model="listQuery.name" placeholder="请输入名称" style="width: 200px;" class="filter-item" clearable/>
      <el-button class="filter-item" type="primary" @click="handleFilter">搜索</el-button>
    </div>
    <el-table
            v-loading="listLoading"
            :data="list"
            element-loading-text="Loading"
            border
            fit
            highlight-current-row>
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="头像" min-width="110" align="center">
        <template slot-scope="scope">
          <!-- <img v-if="scope.row.avatar" :src="scope.row.avatar" class="user-avatar"> -->
          <el-image class="image-url" :src="scope.row.avatar" fit="contain" lazy :preview-src-list="[scope.row.avatar]"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="头衔" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="primary" v-for="(item,index) in scope.row.honors" :key="index" style="margin: 3px;">{{item}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="标签" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="primary" v-for="(item,index) in scope.row.tags" :key="index" style="margin: 3px;">{{item}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center" class-name="small-padding fixed-width" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
    </div>

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="名称" prop="name">
          <el-input type="text" v-model="form.name" clearable />
        </el-form-item>
        <el-form-item label="头像(120*120)" prop="avatar">
          <upload-one v-model="form.avatar" style="width:120px;height:120px"></upload-one>
        </el-form-item>
        <el-form-item label="头衔" prop="honors">
          <div v-for="(item,index) in honorsForm" :key="index" style="margin-bottom: 10px;">
            <el-row style="display: flex;justify-content: flex-start;align-items: center;">
              <el-col :span="14">
                <el-input type="text" v-model="item.honorsTitle" clearable />
              </el-col>
              <el-col :span="10" style="padding-left: 10px;display: flex;justify-content: flex-start;">
                <i class="el-icon-circle-plus" style="font-size: 30px;cursor: pointer;" @click="addHonorsTitle" v-if="index == honorsForm.length-1"></i>
                <i class="el-icon-remove" style="font-size: 30px;cursor: pointer;" @click="delHonorsTitle(index)" v-if="honorsForm.length != 1"></i>
              </el-col>
            </el-row>
          </div>
        </el-form-item>
        <el-form-item label="标签" prop="tags">
          <div v-for="(item,index) in tagsForm" :key="index" style="margin-bottom: 10px;">
            <el-row style="display: flex;justify-content: flex-start;align-items: center;">
              <el-col :span="14">
                <el-input type="text" v-model="item.tagsTitle" clearable />
              </el-col>
              <el-col :span="10" style="padding-left: 10px;display: flex;justify-content: flex-start;">
                <i class="el-icon-circle-plus" style="font-size: 30px;cursor: pointer;" @click="addTagsTitle" v-if="index == tagsForm.length-1"></i>
                <i class="el-icon-remove" style="font-size: 30px;cursor: pointer;" @click="delTagsTitle(index)" v-if="tagsForm.length != 1"></i>
              </el-col>
            </el-row>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

  import request from '@/utils/request'

  export default {
    data() {
      return {
        list: null,
        total: null,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 10,
          name: '',
        },
        dialogFormVisible: false,
        dialogStatus: '',
        textMap: {
          update: '修改',
          create: '新增'
        },
        form: {
          id: '',
          avatar: '',
          name: '',
          honors: [],
          tags: [],
        },
        rules: {
          avatar: [{ required: true, message: '头像不能为空', trigger: 'change' }],
          name: [{ required: true, message: '名称不能为空', trigger: 'change' }],
        },
        btnLoading: false,
        //头衔
        honorsForm:[
          {
            honorsTitle:''
          }
        ],
        //标签
        tagsForm:[
          {
            tagsTitle:''
          }
        ],
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.listLoading = true
        request({
          url: '/api/backend/course/lecturerList',
          method: 'get',
          params: this.listQuery
        }).then(response => {
          this.list = response.data.data
          this.total = response.data.total
          this.listLoading = false
        })
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      handleSizeChange(val) {
        this.listQuery.limit = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.listQuery.page = val
        this.getList()
      },
      resetForm() {
        this.form = {
          id: '',
          avatar: '',
          name: '',
          honors: [],
          tags: [],
        }
        this.btnLoading = false
        this.honorsForm = [
          {
            honorsTitle:''
          }
        ]
        this.tagsForm = [
          {
            tagsTitle:''
          }
        ]
      },
      handleCreate() {
        this.resetForm()
        this.dialogStatus = 'create'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['form'].clearValidate()
        })
      },
      handleUpdate(row) {
        this.resetForm()
        this.form = Object.assign({}, row)
        //头衔
        let honors = this.form.honors||['']
        this.honorsForm = []
        honors.forEach(val =>{
          let obj = {}
          obj.honorsTitle = val
          this.honorsForm.push(obj)
        })
        //标签
        let tags = this.form.tags||['']
        this.tagsForm = []
        tags.forEach(val =>{
          let obj = {}
          obj.tagsTitle = val
          this.tagsForm.push(obj)
        })

        this.dialogStatus = 'update'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['form'].clearValidate()
        })
      },
      saveData() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            //头衔
            this.form.honors = []
            this.honorsForm.forEach(val =>{
              if(val.honorsTitle) this.form.honors.push(val.honorsTitle)
            })
            if(!this.form.honors.length){
              this.$message.warning('头衔不能为空')
              return
            }
            //标签
            this.form.tags = []
            this.tagsForm.forEach(val =>{
              if(val.tagsTitle) this.form.tags.push(val.tagsTitle)
            })
            if(!this.form.tags.length){
              this.$message.warning('标签不能为空')
              return
            }

            // console.log(this.form)
            // return

            this.btnLoading = true
            request({
              url: '/api/backend/course/lecturerStore',
              method: 'post',
              data: this.form
            }).then(response => {
              this.btnLoading = false
              this.dialogFormVisible = false
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.getList();
            }).catch((err) => {
              this.btnLoading = false
            })
          }
        })
      },
      //头衔
      addHonorsTitle(){
        let obj = {
          honorsTitle:''
        }
        this.honorsForm.push(obj)
      },
      delHonorsTitle(idx) {
        this.honorsForm.splice(idx, 1);
      },
      //标签
      addTagsTitle(){
        let obj = {
          tagsTitle:''
        }
        this.tagsForm.push(obj)
      },
      delTagsTitle(idx) {
        this.tagsForm.splice(idx, 1);
      },
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

</style>
