<template>
  <div class="richtext-container">
    <tinymce :height="400" v-model="form.value" :value="form.value"/>
    <el-row style="padding:15px 0;text-align:center">
      <!-- <el-button type="danger" @click="handleClear">清空</el-button> -->
      <el-button type="primary" @click="handleSave">保存</el-button>
    </el-row>
  </div>
</template>

<script>
  import Tinymce from '@/components/Tinymce'
  import request from '@/utils/request'

  export default {
    components: {
      Tinymce
    },
    data() {
      return {
        form: {
          id: '',
          key: "privacyAgreement",
          type: 3,
          value: ''
        }
      }
    },
    created() {
      this.getSetting()
    },
    methods: {
      getSetting() {
        request({
          url: '/api/backend/global/details',
          method: 'get',
          params: { type: 3 }
        }).then(response => {
          if(response.data){
            this.form = response.data
          }
        })
      },
      handleClear() {

      },
      handleSave() {
        this.$confirm('确定要保存, 是否继续?', '提示', {
          type: 'warning'
        }).then(() => {
          request({
            url: '/api/backend/global/store',
            method: 'post',
            data: this.form
          }).then(()=>{
            this.getSetting()
            this.$message({
              type: 'success',
              message: '操作成功'
            });
          })
        }).catch(() => {});
      }
    }

  }
</script>

<style scoped>
  .richtext-container{
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
  }
</style>

