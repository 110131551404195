<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate"
        >新增</el-button
      >
      <el-input
        v-model="listQuery.title"
        placeholder="请输入活动标题"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.is_on"
        placeholder="状态"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="正常" :value="1" />
        <el-option label="已下架" :value="0" />
      </el-select>
      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="活动类型" min-width="110" align="center">
        <template slot-scope="scope">
      {{ scope.row.type }}
        </template>
      </el-table-column>
      <el-table-column label="活动ID" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="活动标题" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.title }}
        </template>
      </el-table-column>
      <el-table-column label="封面" min-width="110" align="center">
        <template slot-scope="scope">
          <!-- <img v-if="scope.row.cover" :src="scope.row.cover" alt="" class="image-url"> -->
          <el-image
            class="image-url-150"
            :src="scope.row.cover"
            fit="contain"
            lazy
            :preview-src-list="[scope.row.cover]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="地点" min-width="150" align="center">
        <template slot-scope="scope">
          <el-tag
            type="primary"
            v-for="(v, i) in scope.row.places"
            :key="i"
            style="margin: 3px"
            >{{ v }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="报名时间" min-width="200" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.enroll_start_time">
            开始时间 {{ scope.row.enroll_start_time }}
          </div>
          <div v-if="scope.row.enroll_end_time">
            结束时间 {{ scope.row.enroll_end_time }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="活动时间" min-width="200" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.start_time">
            开始时间 {{ scope.row.start_time }}
          </div>
          <div v-if="scope.row.end_time">结束时间 {{ scope.row.end_time }}</div>
        </template>
      </el-table-column>
      <el-table-column label="价格(全豆)" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.price }}
        </template>
      </el-table-column>
      <el-table-column label="报名人数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.enroll_num }}
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_on == 1" type="success">正常</el-tag>
          <el-tag v-else type="danger">已下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="250"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)"
            >修改</el-button
          >

          <el-button
            v-if="scope.row.is_on == 1"
            size="mini"
            type="danger"
            @click="handleStatus(scope.row.id, '下架')"
            >下架</el-button
          >
          <el-button
            v-else
            size="mini"
            type="success"
            @click="handleStatus(scope.row.id, '上架')"
            >上架</el-button
          >

          <el-button size="mini" type="primary" @click="enroll(scope.row)"
            >查看报名</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="活动类型" prop="type">
          <el-input type="text" v-model="form.type" clearable />
        </el-form-item>
        <el-form-item label="活动标题" prop="title">
          <el-input type="text" v-model="form.title" clearable />
        </el-form-item>
        <el-form-item label="封面(750*422)" prop="cover">
          <upload-one
            v-model="form.cover"
            style="width: 375px; height: 211px"
          ></upload-one>
        </el-form-item>
        <el-form-item label="价格(全豆)" prop="price">
          <!--          <el-input type="text" v-model="form.price" @input="form.price=form.price.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/\.{2,}/g,'.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')" clearable />-->
          <el-input
            v-model="form.price"
            :min="1"
            :max="100000"
            @input="
              form.price = form.price
                .replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g, '')
                .replace('.', '')
                .replace(/\./g, '')
                .replace('$#$', '')
                .replace(/\.{2,}/g, '')
                .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2')
            "
          ></el-input>
        </el-form-item>
        <el-form-item label="报名名额" prop="num">
          <el-input-number
            v-model="form.num"
            :min="1"
            :max="100000"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="地点" prop="places">
          <el-tag
            :key="tag"
            v-for="tag in form.places"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)"
            >{{ tag }}</el-tag
          >
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
          ></el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="showInput"
            >+ 添加</el-button
          >
        </el-form-item>
        <!--      `places` text COMMENT '地点',-->
        <el-form-item label="报名时间" prop="enroll_start_time">
          <el-date-picker
            v-model="dateArrEnroll"
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            align="right"
            value-format="yyyy-MM-dd HH:mm:ss"
            unlink-panels
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :picker-options="pickerOptions"
            class="filter-item"
            style="display: inline-flex"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="活动时间" prop="start_time">
          <el-date-picker
            v-model="dateArrActivity"
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            align="right"
            value-format="yyyy-MM-dd HH:mm:ss"
            unlink-panels
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :picker-options="pickerOptions"
            class="filter-item"
            style="display: inline-flex"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <tinymce
            :height="400"
            v-model="form.content"
            :value="form.content"
            ref="tinymce"
          />
        </el-form-item>
        <el-form-item label="报名资料:" prop="problem">
          <el-button
            type="primary"
            icon="el-icon-plus"
            circle
            @click="addProblem"
            style="margin-bottom: 5px"
          ></el-button>
          <div v-for="(item, index) in form.problems" :key="index">
            <el-input
              v-model="form.problems[index]"
              style="width: 500px; margin-bottom: 5px"
              placeholder="请输入用户报名所需要的资料，比如姓名"
              type="textarea"
              :rows="2"
              clearable
            ></el-input>
            <el-button
              type="danger"
              icon="el-icon-minus"
              circle
              @click="delProblem(index)"
            ></el-button>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import Tinymce from "@/components/Tinymce";

export default {
  components: {
    Tinymce,
  },
  data() {
    return {
      list: [],
      total: null,
      listLoading: true,
      dateArrEnroll: null,
      dateArrActivity: null,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      listQuery: {
        page: 1,
        limit: 10,
        title: "",
        is_on: "",
      },
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "修改",
        create: "新增",
      },
      form: {
        id: "",
        type: "",
        title: "",
        cover: "",
        price: "",
        num: 1,
        places: [],
        content: "",
        enroll_start_time: "",
        enroll_end_time: "",
        start_time: "",
        end_time: "",
        problems: [],
      },
      rules: {
        type: [
          {
            required: true,
            message: "活动类型不能为空",
            trigger: "change",
          },
        ],
        title: [
          {
            required: true,
            message: "活动标题不能为空",
            trigger: "change",
          },
        ],
        cover: [
          {
            required: true,
            message: "封面不能为空",
            trigger: "change",
          },
        ],
        price: [
          {
            required: true,
            message: "价格不能为空",
            trigger: "change",
          },
        ],
        num: [
          {
            required: true,
            message: "报名名额不能为空",
            trigger: "change",
          },
        ],
        places: [
          {
            required: true,
            message: "地点不能为空",
            trigger: "change",
          },
        ],
        content: [
          {
            required: true,
            message: "内容不能为空",
            trigger: "change",
          },
        ],
        enroll_start_time: [
          {
            required: true,
            message: "报名时间不能为空",
            trigger: "change",
          },
        ],
        start_time: [
          {
            required: true,
            message: "活动时间不能为空",
            trigger: "change",
          },
        ],
      },
      btnLoading: false,
      //地点
      inputVisible: false,
      inputValue: "",
    };
  },
  created() {
    this.getList();
  },
  watch: {
    dateArrEnroll(newVal, oldVal) {
      if (newVal) {
        this.form.enroll_start_time = newVal[0];
        this.form.enroll_end_time = newVal[1];
      } else {
        this.form.enroll_start_time = "";
        this.form.enroll_end_time = "";
      }
    },
    dateArrActivity(newVal, oldVal) {
      if (newVal) {
        this.form.start_time = newVal[0];
        this.form.end_time = newVal[1];
      } else {
        this.form.start_time = "";
        this.form.end_time = "";
      }
    },
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/activity/list",
        method: "get",
        params: this.listQuery,
      }).then((res) => {
        console.log(res);
        this.list = res.data.data;
        this.total = res.data.total;
        this.listLoading = false;
      });
    },
    addProblem() {
      let obj = "";
      this.form.problems.push(obj);
    },
    delProblem(idx) {
      this.form.problems.splice(idx, 1);
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    resetForm() {
      this.form = {
        id: "",
        title: "",
        cover: "",
        price: "",
        num: 1,
        places: [],
        content: "",
        enroll_start_time: "",
        enroll_end_time: "",
        start_time: "",
        end_time: "",
        problems: [],
      };
      this.btnLoading = false;
      this.inputVisible = false;
      this.inputValue = "";
      this.dateArrEnroll = [];
      this.dateArrActivity = [];
    },
    handleCreate() {
      this.resetForm();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      if (this.$refs.tinymce) this.$refs.tinymce.setContent("");
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    handleUpdate(row) {
      this.resetForm();
      this.form = Object.assign({}, row);
      this.dateArrEnroll = [
        this.form.enroll_start_time,
        this.form.enroll_end_time,
      ];
      if (!this.form.problems) {
        this.form.problems = [];
      }
      this.dateArrActivity = [this.form.start_time, this.form.end_time];
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      if (this.$refs.tinymce) this.$refs.tinymce.setContent(row.content);
      this.$nextTick(() => {
        console.log(this.form);
        this.$refs["form"].clearValidate();
      });
    },
    saveData() {
      if (this.form.enroll_start_time > this.form.enroll_end_time) {
        this.$message({
          type: "error",
          message: "报名开始时间不能大于报名结束时间",
        });
        return;
      } else if (this.form.enroll_end_time > this.form.start_time) {
        this.$message({
          type: "error",
          message: "报名结束时间不能大于活动开始时间",
        });
        return;
      } else if (this.form.start_time > this.form.end_time) {
        this.$message({
          type: "error",
          message: "活动开始时间不能大于活动结束时间",
        });
        return;
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // console.log(this.form)
          // return;
          this.btnLoading = true;
          request({
            url: "/api/backend/activity/store",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    //地点
    handleClose(tag) {
      this.form.places.splice(this.form.places.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.form.places.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    //上下架
    handleStatus(val, text) {
      this.$confirm("此操作将" + text + "该活动, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/activity/upDown ",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    //查看报名
    enroll(row) {
      this.$router.push(`/activities/enroll?activity_id=${row.id}`);
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.el-tag {
  margin-right: 10px;
}
.button-new-tag {
  margin-right: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-right: 10px;
  vertical-align: bottom;
}
</style>
