<template>
  <div style="margin: 20px">
    <div>
      <el-radio-group v-model="examine_a" size="small" @change="changeA">
        <el-radio :label="1" border>审核中</el-radio>
        <el-radio :label="2" border>审核完成</el-radio>
      </el-radio-group>
      <span style="margin-left: 20px">A</span>
    </div>
    <div style="margin-top: 50px">
      <el-radio-group v-model="examine_b" size="small" @change="changeB">
        <el-radio :label="1" border>审核中</el-radio>
        <el-radio :label="2" border>审核完成</el-radio>
      </el-radio-group>
      <span style="margin-left: 20px">B</span>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      examine_a: "",
      a_id: "",
      examine_b: "",
      b_id: "",
    };
  },
  created() {
    this.getSetting();
  },
  methods: {
    getSetting() {
      request({
        url: "/api/backend/getGlobalSetting",
        method: "get",
        params: { type: 10, id: "" },
      }).then((res) => {
        if (res.data) {
          res.data.map((v) => {
            if (v.key == "examine_b") {
              this.examine_b = v.value;
              this.b_id = v.id;
            } else {
              this.examine_a = v.value;
              this.a_id = v.id;
            }
          });
        }
      });
    },
    changeA(e) {
      request({
        url: "/api/backend/global/store",
        method: "post",
        data: { id: this.a_id, key: "examine_a", value: e, type: 10 },
      }).then((res) => {
        this.$message({
          type: "success",
          message: "保存成功!",
        });
        this.getSetting();
      });
    },
    changeB(e) {
      request({
        url: "/api/backend/global/store",
        method: "post",
        data: { id: this.b_id, key: "examine_b", value: e, type: 10 },
      }).then((res) => {
        this.$message({
          type: "success",
          message: "保存成功!",
        });
        this.getSetting();
      });
    },
  },
};
</script>

