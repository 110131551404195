<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate"
        >新增</el-button
      >
      <el-input
        v-model="listQuery.name"
        placeholder="请输入名称"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.status"
        placeholder="状态"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="正常" :value="1" />
        <el-option label="已下架" :value="2" />
      </el-select>
      有效期
      <el-date-picker
        v-model="dateArr"
        type="datetimerange"
        :default-time="['00:00:00', '23:59:59']"
        align="right"
        value-format="yyyy-MM-dd HH:mm:ss"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        class="filter-item"
        style="display: inline-flex"
      ></el-date-picker>
      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="优惠券id" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="类型" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.type == 1" type="danger">打折券</el-tag>
          <el-tag v-else-if="scope.row.type == 2" type="warning">立减券</el-tag>
          <el-tag v-else-if="scope.row.type == 4" type="success">免费券</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="折扣" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.value }}
        </template>
      </el-table-column>
      <el-table-column label="门槛" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.condition }}
        </template>
      </el-table-column>
      <el-table-column label="有效天数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.valid_day }}
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status == 1" type="success">正常</el-tag>
          <el-tag v-else type="danger">已下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="280"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)"
            >修改</el-button
          >

          <el-button size="mini" type="warning" @click="toReceive(scope.row)"
            >领取人列表</el-button
          >

          <el-button size="mini" type="success" @click="toUseRecord(scope.row)"
            >使用记录</el-button
          >

          <el-button
            v-if="scope.row.status == 1"
            size="mini"
            type="danger"
            @click="handleStatus(scope.row.id, '下架')"
            >下架</el-button
          >
          <el-button
            v-else
            size="mini"
            type="success"
            @click="handleStatus(scope.row.id, '上架')"
            >上架</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-table
      style="margin-top: 15px"
      v-loading="listLoading"
      :data="list2"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="优惠券id" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="类型" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.type == 1" type="danger">打折券</el-tag>
          <el-tag v-else-if="scope.row.type == 2" type="warning">立减券</el-tag>
          <el-tag v-else-if="scope.row.type == 3" type="success">免费券</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="折扣/立减" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.type == 1?((scope.row.value - 0) + '折'):scope.row.type == 2?('立减' + (scope.row.value - 0) + '全豆'):'免费' }}
        </template>
      </el-table-column>
      <el-table-column label="门槛" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.type == 3?'-':scope.row.condition }}
        </template>
      </el-table-column>
      <el-table-column label="有效天数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.valid_day }}
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status == 1" type="success">正常</el-tag>
          <el-tag v-else type="danger">已下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="280"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)"
            >修改</el-button
          >

          <el-button size="mini" type="warning" @click="toReceive(scope.row)"
            >领取人列表</el-button
          >

          <el-button size="mini" type="success" @click="toUseRecord(scope.row)"
            >使用记录</el-button
          >

          <el-button
            v-if="scope.row.status == 1"
            size="mini"
            type="danger"
            @click="handleStatus(scope.row.id, '下架')"
            >下架</el-button
          >
          <el-button
            v-else
            size="mini"
            type="success"
            @click="handleStatus(scope.row.id, '上架')"
            >上架</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="800px"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="名称" prop="name">
          <el-input
            type="text"
            v-model="form.name"
            placeholder="3个字以内"
            maxlength="3"
            clearable
          />
        </el-form-item>
        <el-form-item label="类型" prop="type" >
          <el-select v-model="form.type" clearable >
            <el-option label="打折券" :value="1" v-if="form.type!=4"/>
            <el-option label="立减券" :value="2" v-if="form.type!=4"/>
            <el-option label="免费券" :value="3" v-if="form.type!=4"/>
            <el-option label="签到免费券" :value="4" v-else/>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="使用规则" prop="use_rule">
          <el-input type="textarea" :rows="4" v-model="form.use_rule" />
        </el-form-item> -->
        <el-form-item label="使用范围" prop="coupon_scope">
          <el-select v-model="form.coupon_scope" clearable>
            <el-option label="全部课程" :value="1" />
            <!-- <el-option label="指定课程分类" :value="2" />
            <el-option label="指定课程" :value="3" /> -->
            <el-option label="全部活动" :value="4" />
          </el-select>
        </el-form-item>
        <el-form-item
          label="指定课程分类"
          prop=""
          v-if="form.coupon_scope == 2"
        >
          <template>
            <el-transfer
              v-model="course_category_ids"
              filterable
              :filter-method="filterMethod"
              filter-placeholder="请输入课程分类"
              :titles="['课程分类列表', '指定的课程分类']"
              :data="data"
            />
          </template>
        </el-form-item>
        <el-form-item label="指定课程" prop="" v-if="form.coupon_scope == 3">
          <template>
            <el-transfer
              v-model="course_ids"
              filterable
              :filter-method="filterMethod"
              filter-placeholder="请输入课程"
              :titles="['课程列表', '指定的课程']"
              :data="data1"
            />
          </template>
        </el-form-item>
        <el-form-item :label="form.type == 1?'折扣':form.type == 2?'立减':''" prop="value" v-if="form.type&&form.type != 3">
          <el-input
            v-if="form.type == 1"
            type="number"
            v-model="form.value"
            placeholder="请输入折扣 0~9.9"
            clearable
          />
          <el-input
            v-if="form.type == 2"
            type="number"
            v-model="form.value"
            placeholder="请输入立减金额"
            clearable
          />
        </el-form-item>
        <el-form-item label="门槛" prop="condition" v-if="form.type !== 3">
          <el-input
            type="number"
            v-model="form.condition"
            placeholder="无门槛则为0"
            clearable
          />
        </el-form-item>
        <el-form-item label="有效天数" prop="valid_day">
          <el-input
            type="number"
            v-model="form.valid_day"
            placeholder="请输入有效天数"
            clearable
          />
        </el-form-item>
        <!--      `name` varchar(255) NOT NULL DEFAULT '' COMMENT '优惠券名称',-->
        <!--      `value` decimal(10,2) unsigned NOT NULL COMMENT '优惠券面额 1.打折券单位：例0.75为75折',-->
        <!--      `valid_day` int(5) NOT NULL COMMENT '有效天数',-->
        <!--      `condition` int(10) unsigned DEFAULT '0' COMMENT '门槛 元，满xx可用',-->
        <!--      `coupon_type` tinyint(2) DEFAULT '1' COMMENT '优惠类型, 1:无门槛  2:满减',-->
        <!--      `coupon_scope` tinyint(2) DEFAULT '1' COMMENT '使用范围, 1.全部课程 2.指定课程分类 3.指定课程 4.全部活动',-->
        <!--      `course_category_ids` text COMMENT '指定课程分类可用,json数组',-->
        <!--      `course_ids` text COMMENT '指定课程可用,json数组',-->
        <!--      `activity_ids` text COMMENT '指定活动可用,json数组',-->
        <!--      `type` tinyint(1) NOT NULL COMMENT '类型 1打折券 2立减券 ',-->
        <!--      `status` tinyint(2) NOT NULL DEFAULT '2' COMMENT '上下架, 1:上架 2:下架',-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    const generateData = (_) => {
      const data = [];
      request({
        url: "/api/backend/course/categoryList",
        method: "get",
        params: {
          limit: 10000000000,
          is_on: 1,
          title: "",
          is_index_show: "",
        },
      }).then((response) => {
        response.data.data.forEach((courseCategory, index) => {
          data.push({
            label: courseCategory.title,
            key: courseCategory.id,
            spell: courseCategory.title,
          });
        });
      });
      return data;
    };
    const generateData1 = (_) => {
      const data = [];
      request({
        url: "/api/backend/course/list",
        method: "get",
        params: {
          limit: 10000000000,
          is_on: 1,
          title: "",
          is_recommend: "",
          pay_rule: "",
        },
      }).then((response) => {
        response.data.data.forEach((course, index) => {
          data.push({
            label: course.title,
            key: course.id,
            spell: course.title,
          });
        });
      });
      return data;
    };
    return {
      data: generateData(),
      data1: generateData1(),
      course_category_ids: [],
      course_ids: [],
      form: {
        id: "",
        name: "",
        use_rule: "",
        course_category_ids: [],
        course_ids: [],
        activity_ids: [],
        weight: 1,
        type: "",
      },
      filterMethod(query, item) {
        return item.spell.indexOf(query) > -1;
      },
      list: null,
      total: null,
      listLoading: true,
      dateArr: null,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      listQuery: {
        page: 1,
        limit: 10,
        type: "",
        name: "",
        status: "",
        startTime: "",
        endTime: "",
      },
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "修改",
        create: "新增",
      },

      rules: {
        name: [{ required: true, message: "名称不能为空", trigger: "change" }],
        weight: [
          { required: true, message: "权重不能为空", trigger: "change" },
        ],
        type: [{ required: true, message: "类型不能为空", trigger: "change" }],
        // use_rule: [
        //   { required: true, message: "使用规则不能为空", trigger: "change" },
        // ],
        condition: [
          { required: true, message: "门槛不能为空", trigger: "change" },
        ],
        value: [
          { required: true, message: "优惠券面额不能为空", trigger: "change" },
        ],
        valid_day: [
          { required: true, message: "有效天数不能为空", trigger: "change" },
        ],
        coupon_scope: [
          { required: true, message: "使用范围不能为空", trigger: "change" },
        ],
      },
      btnLoading: false,
      list2: null,
    };
  },
  created() {
    this.getListType1();
    this.getListType2();
  },
  watch: {
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.startTime = newVal[0];
        this.listQuery.endTime = newVal[1];
      } else {
        this.listQuery.startTime = "";
        this.listQuery.endTime = "";
      }
    },
  },
  methods: {
    getListType1() {
      this.listLoading = true;
      request({
        url: "/api/backend/freeCoupon",
        method: "get",
        params: {},
      }).then((response) => {
        this.list = response.data;
        this.listLoading = false;
      });
    },
    getListType2() {
      let listQuery = this.listQuery;
      // listQuery.type = 2;
      this.listLoading = true;
      request({
        url: "/api/backend/coupon",
        method: "get",
        params: listQuery,
      }).then((response) => {
        this.list2 = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getListType1();
      this.getListType2();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getListType1();
      this.getListType2();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getListType1();
      this.getListType2();
    },
    resetForm() {
      this.form = {
        id: "",
        name: "",
        use_rule: "",
        parent_id: "",
        weight: 1,
        type: "",
      };
      this.btnLoading = false;
    },
    handleCreate() {
      this.resetForm();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
      
    },
    handleUpdate(row) {
      this.resetForm();
      this.form = Object.assign({}, row);
      this.course_category_ids = this.form.course_category_ids;
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    saveData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form.course_category_ids = [];
          this.form.course_ids = [];
          this.form.activity_ids = [];
          if (this.form.coupon_scope == 2) {
            if (!this.course_category_ids.length) {
              this.$message({
                type: "error",
                message: "指定课程分类不能为空",
              });
              return;
            }
            this.form.course_category_ids = this.course_category_ids;
          }
          if (this.form.coupon_scope == 3) {
            if (!this.course_ids.length) {
              this.$message({
                type: "error",
                message: "指定课程不能为空",
              });
              return;
            }
            this.form.course_ids = this.course_ids;
          }
          if(this.form.type == 3){
            this.form.value=0;
          }
          this.btnLoading = true;
          request({
            url: "/api/backend/coupon",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getListType1();
              this.getListType2();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    //领取人列表
    toReceive(row) {
      this.$router.push("/operate/receive?id=" + row.id);
    },
    //使用记录
    toUseRecord(row) {
      this.$router.push("/operate/useCouponRecord?id=" + row.id);
    },
    handleClose(tag) {
      this.form.course_category_ids.splice(
        this.form.course_category_ids.indexOf(tag),
        1
      );
    },
    //上下架
    handleStatus(val, text) {
      this.$confirm("此操作将" + text + "该优惠券, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/coupon/upDown",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getListType1();
            this.getListType2();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
