<template>
  <div class="app-container">
    <div class="filter-container">
      <!-- <el-input
        v-model="listQuery.nickname"
        placeholder="请输入用户昵称"
        style="width: 200px"
        class="filter-item"
        clearable
      /> -->
      <el-date-picker
        v-model="dateArr"
        type="datetimerange"
        :default-time="['00:00:00', '23:59:59']"
        align="right"
        value-format="yyyy-MM-dd HH:mm:ss"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        class="filter-item"
        style="display: inline-flex"
      ></el-date-picker>
      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>
    <div class="amount">
      <div class="flex">
        <span>￥</span>总金额: {{ total_monry||0}}
      </div>
    </div>
    <!-- <div class="amount"><div class="flex"><span>￥</span>总VIP付费: {{total_vip}}</div></div>
    <div class="amount"><div class="flex"><span>￥</span>平台课程收入: {{total_course}}</div></div>
    <div class="amount"><div class="flex"><span>￥</span>用户余额: {{total_user_balance}}</div></div> -->
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="学生姓名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user ? scope.row.student.name : "" }}
        </template>
      </el-table-column>
      <el-table-column label="昵称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user ? scope.row.user.nickname : "" }}
        </template>
      </el-table-column>
      <el-table-column label="头像" min-width="110" align="center">
        <template slot-scope="scope">
          <img
            v-if="scope.row.user && scope.row.user.avatar"
            :src="scope.row.user.avatar"
            class="user-avatar"
          />
        </template>
      </el-table-column>
      <!-- <el-table-column label="联系电话" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user?scope.row.user.phone:'' }}
        </template>
      </el-table-column> -->
      <el-table-column label="课程名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.other_course_school.other_course.name }}
        </template>
      </el-table-column>
      <el-table-column label="课程金额" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.price }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="获得全豆" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.bean_num }}
        </template>
      </el-table-column>
      <el-table-column label="充值后全豆" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.after_bean_num }}
        </template>
      </el-table-column> -->
      <el-table-column label="购买时间" min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      dateArr: null,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      listQuery: {
        page: 1,
        limit: 10,
        start_at: "",
        end_at: "",
        nickname: "",
      },
      total_recharge: "",
      total_vip: "",
      total_course: "",
      total_user_balance: "",
    };
  },
  created() {
    this.getUserAccountLog();
    //   this.getDataCount()
  },
  watch: {
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.start_at = newVal[0];
        this.listQuery.end_at = newVal[1];
      } else {
        this.listQuery.start_at = "";
        this.listQuery.end_at = "";
      }
    },
  },
  methods: {
    getUserAccountLog() {
      this.listLoading = true;
      request({
        url: "/api/backend/otherCourse/orderLog",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        console.log(response);
        this.list = response.data.data;
        this.total = response.data.total;
        this.total_monry=response.data.total_monry
        this.listLoading = false;
      });
    },
    getDataCount() {
      this.listLoading = true;
      request({
        url: "/api/backend/otherCourse/orderLog",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.total_recharge = response.data.total_monry;
        this.total_vip = response.data.total_vip;
        this.total_course = response.data.total_course;
        this.total_user_balance = response.data.total_user_balance;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getUserAccountLog();
      // this.getDataCount()
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getUserAccountLog();
      // this.getDataCount()
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getUserAccountLog();
      // this.getDataCount()
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.amount {
  display: inline-block;
  padding: 20px 30px;
  margin-bottom: 20px;
  margin-right: 20px;
  color: #ffffff;
  background-color: rgba(255, 0, 0, 0.438);
  border-radius: 40px;
  .flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
      font-size: 30px;
      font-weight: bold;
    }
  }
}
</style>
