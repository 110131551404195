<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.keyword"
        placeholder="请输入用户名/电话"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <!-- <el-select
        v-model="listQuery.project_type"
        placeholder="项目类型"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-input
        v-model="listQuery.keyword"
        placeholder="请输入项目类型"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      </el-select> -->
      <el-input
        v-model="listQuery.team_name"
        placeholder="战队名"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-input
        v-model="listQuery.team_leader_name"
        placeholder="队长名"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
      <span class="filter-item">
        <download-excel
          :fields="json_fields"
          :fetch="out"
          :before-generate="startPost"
          :before-finish="endPost"
          :header="'报名信息'"
          type="xls"
          worksheet="报名信息"
          name="报名信息.xls"
        >
          <el-button type="success"> 导出报名信息 </el-button>
        </download-excel>
      </span>
      <el-button
        class="filter-item"
        type="warning"
        @click="$router.go(-1)"
        style="float: right"
        >返回</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="用户ID" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.row.user ? scope.row.user.id : "" }}
        </template>
      </el-table-column>
      <el-table-column label="头像" min-width="110" align="center">
        <template slot-scope="scope">
          <img
            v-if="scope.row.user && scope.row.user.avatar"
            :src="scope.row.user.avatar"
            class="user-avatar"
          />
        </template>
      </el-table-column>
      <el-table-column label="昵称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user ? scope.row.user.nickname : "" }}
        </template>
      </el-table-column>
      <el-table-column label="联系电话" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user ? scope.row.user.phone : "" }}
        </template>
      </el-table-column>
      <el-table-column label="性别" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.user && scope.row.user.gender == 0"
            type="info"
            >未设置</el-tag
          >
          <el-tag
            v-else-if="scope.row.user && scope.row.user.gender == 1"
            type="primary"
            >男</el-tag
          >
          <el-tag
            v-else-if="scope.row.user && scope.row.user.gender == 2"
            type="danger"
            >女</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="项目类型" min-width="110" align="center">
        <template slot-scope="scope">
        {{ scope.row.activity ? scope.row.activity.type : "" }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="战队名" min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.team_name }}
        </template>
      </el-table-column>
      <el-table-column label="队长名" min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.team_leader_name }}
        </template>
      </el-table-column> -->
      <el-table-column label="报名时间" min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.user && scope.row.status == 1" type="success"
            >已报名</el-tag
          >
          <el-tag
            v-else-if="scope.row.user && scope.row.status == 2"
            type="danger"
            >已取消</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            @click="handleStatus(scope.row.id)"
            :disabled="scope.row.status == 2"
          >
            取消报名</el-button
          >
          <el-button size="mini" type="primary" @click="showDialog(scope.row)"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      title="报名详情"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      v-if="one_form"
    >
      <el-form ref="form" :model="one_form" label-width="120px">
        <!-- <el-form-item label="参赛项目">
          <el-tag v-if="one_form.project_type == 1" type="primary"
            >英雄联盟</el-tag
          >
          <el-tag v-else-if="one_form.project_type == 2" type="primary"
            >王者荣耀</el-tag
          >
        </el-form-item>
        <el-form-item label="战队名">
          <span style="color: #1890ff">{{
            one_form.form_data ? one_form.form_data.team_name : ""
          }}</span>
        </el-form-item> -->
        <template v-if="one_form.form_data">
          <el-form-item v-for="(v, i) in one_form.form_data.list" :key="i">
            <div>
              <div style="width: 260px; display: inline-block; color: #1890ff">
                <span style="color: #606266; fontweight: 600"
                  >{{ v.problem || "" }}：</span
                >{{ v.answer || "" }}
              </div>
              <!-- <div style="width: 300px; display: inline-block; color: #1890ff">
                <span style="color: #606266; fontweight: 600">身份证号：</span
                >{{ v.idcard || "" }}
              </div> -->
            </div>
          </el-form-item>
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogFormVisible = false"
          >关闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import downloadExcel from "vue-json-excel";
export default {
  components: { downloadExcel },
  data() {
    return {
      dialogFormVisible: false,
      one_form: null,
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        activity_id: "",
        project_type: "",
        team_name: "",
        team_leader_name: "",
      },
      //用户
      json_fields: {
        "用户ID ": "user_id",
        "昵称 ": "user.nickname",
        "联系电话 ": "user.phone",
        "性别 ": {
          field: "user.gender",
          //自定义回调函数
          callback: (value) => {
            if (value == 0) {
              return "未设置";
            } else if (value == 1) {
              return "男";
            } else if (value == 2) {
              return "女";
            }
          },
        },
        "报名时间 ": {
          field: "created_at",
          //自定义回调函数
          callback: (value) => {
            return "&nbsp;" + value;
          },
        },
        "参赛项目": "activity.type",
        "报名信息": {
          field: "form_data.list",
          //自定义回调函数
          callback: (value) => {
            var datas = "";
            value.forEach((a) => {
              datas +=
                '<div style="width: 260px; display: inline-block; color: #1890ff"><span style="color: #606266; fontweight: 600">' +
                a.problem +
                "：</span>" +
                a.answer +
                "</div>";
            });
            return datas;
          },
        },
        // "队长姓名: {
        //   field: "form_data.list",
        //   //自定义回调函数
        //   callback: (value) => {
        //     return  value[0]['name'];
        //   },
        // },
        // 队长电话: {
        //   field: "form_data.list",
        //   //自定义回调函数
        //   callback: (value) => {
        //     return  value[0]['phone'];
        //   },
        // },
        // 队长身份证号: {
        //   field: "form_data.list",
        //   //自定义回调函数
        //   callback: (value) => {
        //     return "<p style=\"mso-number-format:'\\@';\">"+value[0]['idcard']+"</p>" ;
        //   },
        // },
        // 首发队员1姓名: {
        //   field: "form_data.list",
        //   //自定义回调函数
        //   callback: (value) => {
        //     return  value[1]['name'];
        //   },
        // },
        // 首发队员1电话: {
        //   field: "form_data.list",
        //   //自定义回调函数
        //   callback: (value) => {
        //     return  value[1]['phone'];
        //   },
        // },
        // 首发队员1身份证号: {
        //   field: "form_data.list",
        //   //自定义回调函数
        //   callback: (value) => {
        //     return  "<p style=\"mso-number-format:'\\@';\">"+value[1]['idcard']+"</p>";
        //   },
        // },
        // 首发队员2姓名: {
        //   field: "form_data.list",
        //   //自定义回调函数
        //   callback: (value) => {
        //     return  value[2]['name'];
        //   },
        // },
        // 首发队员2电话: {
        //   field: "form_data.list",
        //   //自定义回调函数
        //   callback: (value) => {
        //     return  value[2]['phone'];
        //   },
        // },
        // 首发队员2身份证号: {
        //   field: "form_data.list",
        //   //自定义回调函数
        //   callback: (value) => {
        //     return  "<p style=\"mso-number-format:'\\@';\">"+value[2]['idcard']+"</p>";
        //   },
        // },
        // 首发队员3姓名: {
        //   field: "form_data.list",
        //   //自定义回调函数
        //   callback: (value) => {
        //     return  value[3]['name'];
        //   },
        // },
        // 首发队员3电话: {
        //   field: "form_data.list",
        //   //自定义回调函数
        //   callback: (value) => {
        //     return  value[3]['phone'];
        //   },
        // },
        // 首发队员3身份证号: {
        //   field: "form_data.list",
        //   //自定义回调函数
        //   callback: (value) => {
        //     return  "<p style=\"mso-number-format:'\\@';\">"+value[3]['idcard']+"</p>";
        //   },
        // },
        // 首发队员4姓名: {
        //   field: "form_data.list",
        //   //自定义回调函数
        //   callback: (value) => {
        //     return  value[4]['name'];
        //   },
        // },
        // 首发队员4电话: {
        //   field: "form_data.list",
        //   //自定义回调函数
        //   callback: (value) => {
        //     return  value[4]['phone'];
        //   },
        // },
        // 首发队员4身份证号: {
        //   field: "form_data.list",
        //   //自定义回调函数
        //   callback: (value) => {
        //     return  "<p style=\"mso-number-format:'\\@';\">"+value[4]['idcard']+"</p>";
        //   },
        // },

        // 替补队员姓名: {
        //   field: "form_data.list",
        //   //自定义回调函数
        //   callback: (value) => {
        //     return  value[5]['name'];
        //   },
        // },
        // 替补队员电话: {
        //   field: "form_data.list",
        //   //自定义回调函数
        //   callback: (value) => {
        //     return  value[5]['phone'];
        //   },
        // },
        // 替补队员身份证号:{
        //   field: "form_data.list",
        //   //自定义回调函数
        //   callback: (value) => {
        //     return  "<p style=\"mso-number-format:'\\@';\">"+value[5]['idcard']+"</p>";
        //   },
        // },
      },
    };
  },
  created() {
    this.listQuery.activity_id = this.$route.query.activity_id;
    this.getList();
  },
  methods: {
    showDialog(row) {
      this.one_form = row;
      this.dialogFormVisible = true;
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/activity/enrollList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    startPost() {
      this.loading = true;
    },
    endPost() {
      this.loading = false;
    },
    out() {
      this.listLoading = true;
      return request({
        url: "/api/backend/activity/enrollList",
        method: "get",
        params: {
          page: 1,
          limit: 999999999999,
          keyword: "",
          activity_id: this.listQuery.activity_id,
          project_type: "",
          team_name: "",
          team_leader_name: "",
          status: 1,
        },
      })
        .then((result) => {
          var json_data = result.data.data;

          console.log(json_data);

          return json_data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    handleStatus(val) {
      this.$confirm("确定要取消该报名, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/activity/cancelEnroll",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
