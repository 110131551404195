<template>
    <div class="app-container">
        <div class="filter-container">
            <el-input v-model="listQuery.company_name" placeholder="请输入公司名称" style="width: 200px" class="filter-item"
                clearable />
            <el-input v-model="listQuery.post_name" placeholder="请输入职位名称" style="width: 200px" class="filter-item"
                clearable />
            <el-input v-model="listQuery.name" placeholder="请输入姓名" style="width: 200px" class="filter-item" clearable />
            <el-select v-model="listQuery.status" placeholder="状态" clearable style="width: 150px" class="filter-item">
                <el-option label="未处理" :value="0" />
                <el-option label="已通过" :value="1" />
                <el-option label="已拒绝" :value="2" />
            </el-select>
            <el-button class="filter-item" type="primary" @click="handleFilter">搜索</el-button>
        </div>
        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" border fit highlight-current-row>
            <el-table-column label="序号" width="95" align="center">
                <template slot-scope="scope">
                    {{ scope.$index + 1 }}
                </template>
            </el-table-column>
            <el-table-column label="公司名称" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.company ? scope.row.company.name : "" }}
                </template>
            </el-table-column>
            <el-table-column label="职位名称" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.post ? scope.row.post.name : "" }}
                </template>
            </el-table-column>
            <el-table-column label="职位类型" min-width="150" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.post && scope.row.post.post_category_parent" type="primary" style="margin: 3px">{{
                        scope.row.post.post_category_parent.name }}</el-tag>
                    <el-tag v-if="scope.row.post && scope.row.post.post_category" type="success" style="margin: 3px">{{
                        scope.row.post.post_category.name }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="证件照" min-width="110" align="center">
                <template slot-scope="scope">
                    <!-- <img v-if="scope.row.resume_snap && scope.row.resume_snap.id_photo" :src="scope.row.resume_snap.id_photo" class="image-url" /> -->
                    <el-image style="width:80px" fit="fill" :src="scope.row.resume_snap.id_photo" lazy :preview-src-list="[scope.row.resume_snap.id_photo]"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="姓名" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.name }}
                </template>
            </el-table-column>
            <el-table-column label="性别" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.resume_snap && scope.row.resume_snap.gender == 1" type="primary">男</el-tag>
                    <el-tag v-else-if="scope.row.resume_snap && scope.row.resume_snap.gender == 2" type="danger">女</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="学历" min-width="110" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.resume_snap && scope.row.resume_snap.education == 1">大专及以下</span>
                    <span v-else-if="
              scope.row.resume_snap && scope.row.resume_snap.education == 2
            ">本科</span>
                    <span v-else-if="
              scope.row.resume_snap && scope.row.resume_snap.education == 3
            ">本科以上</span>
                </template>
            </el-table-column>
            <el-table-column label="联系方式" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.resume_snap ? scope.row.resume_snap.phone : "" }}
                </template>
            </el-table-column>
            <el-table-column label="状态" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.status == 0" type="warning">待处理</el-tag>
                    <el-tag v-else-if="scope.row.status == 1" type="success">已通过</el-tag>
                    <el-tag v-else type="danger">已拒绝</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="提交时间" min-width="200" align="center">
                <template slot-scope="scope">
                    {{ scope.row.created_at }}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200" align="center" class-name="small-padding fixed-width" fixed="right">
                <template slot-scope="scope">
                    <el-button size="mini" type="success" @click="show(scope.row.id)" v-if="scope.row.status == 0">通过</el-button>
                    <el-button size="mini" type="danger" @click="resumeRefuse(scope.row.id)" v-if="scope.row.status == 0">拒绝</el-button>
                    <el-button size="mini" type="primary" @click="handleInfo(scope.row)">查看详情</el-button>
                    <el-button size="mini" type="warning" @click="resumeDel(scope.row.id)" v-if="scope.row.status == 0">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[10, 20, 30, 50]" :page-size="listQuery.limit"
                :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" />
        </div>

        <el-dialog title="简历详情" :visible.sync="showDataInfo">
            <el-form :model="dataInfo" label-width="100px" v-if="dataInfo != null">
                <el-form-item label="基本信息" class="fs-16">
                    <el-form-item label="证件照" label-width="50px">
                        <!-- <img v-if="dataInfo.resume_snap && dataInfo.resume_snap.id_photo" :src="dataInfo.resume_snap.id_photo" class="image-url" /> -->
                        <el-image style="width:80px" fit="fill" :src="dataInfo.resume_snap.id_photo" lazy
                            :preview-src-list="[dataInfo.resume_snap.id_photo]"></el-image>
                    </el-form-item>
                    <el-form-item label="姓名" label-width="50px">
                        {{ dataInfo.name }}
                    </el-form-item>
                    <el-form-item label="年龄" label-width="50px">
                        {{ dataInfo.resume_snap ? dataInfo.resume_snap.age : "" }}
                    </el-form-item>
                    <el-form-item label="性别" label-width="50px">
                        <el-tag v-if="dataInfo.resume_snap && dataInfo.resume_snap.gender == 1" type="primary">男</el-tag>
                        <el-tag v-else-if="
                dataInfo.resume_snap && dataInfo.resume_snap.gender == 2
              "
                            type="danger">女</el-tag>
                    </el-form-item>
                    <el-form-item label="学历" label-width="50px">
                        <span v-if="dataInfo.resume_snap && dataInfo.resume_snap.education == 1">大专及以下</span>
                        <span v-else-if="
                dataInfo.resume_snap && dataInfo.resume_snap.education == 2
              ">本科</span>
                        <span v-else-if="
                dataInfo.resume_snap && dataInfo.resume_snap.education == 3
              ">本科以上</span>
                    </el-form-item>
                    <el-form-item label="联系方式" label-width="80px">
                        {{ dataInfo.resume_snap ? dataInfo.resume_snap.phone : "" }}
                    </el-form-item>
                    <el-form-item label="邮箱" label-width="50px">
                        {{ dataInfo.resume_snap ? dataInfo.resume_snap.email : "" }}
                    </el-form-item>
                </el-form-item>

                <el-form-item label="工作经历" class="fs-16">
                    <!-- dataInfo.resume_snap.work_experience -->
                    <div v-for="(v, i) in dataInfo.resume_snap.work_experience" :key="i" style="marginTop: 15px; padding: 20px; border: 2px solid #dddddd">
                        <el-form-item label="单位名称" label-width="80px">
                            {{ v.company }}
                        </el-form-item>
                        <el-form-item label="职位名称" label-width="80px">
                            {{ v.position }}
                        </el-form-item>
                        <el-form-item label="入职年份" label-width="80px">
                            {{ v.start_year }}
                        </el-form-item>
                        <el-form-item label="离职年份" label-width="80px">
                            {{ v.end_year }}
                        </el-form-item>
                        <el-form-item label="工作内容" label-width="80px">
                            {{ v.description }}
                        </el-form-item>
                    </div>
                </el-form-item>

                <el-form-item label="教育经历" class="fs-16">
                    <!-- dataInfo.resume_snap.educational_experience -->
                    <div v-for="(v, i) in dataInfo.resume_snap.educational_experience" :key="i" style="marginTop: 15px; padding: 20px; border: 2px solid #dddddd">
                        <el-form-item label="学校名称" label-width="80px">
                            {{ v.school }}
                        </el-form-item>
                        <el-form-item label="学历" label-width="80px">
                            {{ eduList[eduList.findIndex(x=>x.value == v.education)].label }}
                        </el-form-item>
                        <el-form-item label="专业名称" label-width="80px">
                            {{ v.specialty }}
                        </el-form-item>
                        <el-form-item label="入学年份" label-width="80px">
                            {{ v.start_year }}
                        </el-form-item>
                        <el-form-item label="毕业年份" label-width="80px">
                            {{ v.end_year }}
                        </el-form-item>
                        <el-form-item label="教育描述" label-width="80px">
                            {{ v.description }}
                        </el-form-item>
                    </div>
                </el-form-item>

                <el-form-item label="自我简述" class="fs-16">
                    {{ dataInfo.resume_snap ? dataInfo.resume_snap.self_introduction : "" }}
                </el-form-item>
                <el-form-item label="附件" class="fs-16">
                   <div v-for="(v,i) in dataInfo.resume_snap.enclosures" :key="i" style="display:flex;alignItems: center;justifyContent: space-between;textDecoration: underline;">
                     <span>{{v.name}}</span>  
                     <a :href="v.url" style="color:blue;marginRight:30px">下载</a>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="简历通过" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
            <el-form ref="form" :rules="rules" :model="form" label-width="80px">
                <el-form-item label="通知内容" prop="content">
                    <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 8}" v-model="form.content" clearable />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="resumePass" :loading="btnLoading">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        company_id: "",
        post_id: "",
        company_name: "",
        post_name: "",
        name: "",
        status: ""
      },
      company_id: "",
      post_id: "",
      dataInfo: null,
      showDataInfo: false,
      dialogFormVisible: false,
      form: {
        post_resume_id: "",
        content: ""
      },
      btnLoading: false,
      rules: {
        content: [
          {
            required: true,
            message: "通知内容不能为空",
            trigger: "change"
          }
        ]
      },

      eduList: [
        { value: 1, label: "大专及以下" },
        { value: 2, label: "本科" },
        { value: 3, label: "本科以上" }
      ]
    };
  },
  created() {
    this.company_id = this.listQuery.company_id =
      this.$route.query.company_id - 0;
    this.post_id = this.listQuery.post_id = this.$route.query.post_id - 0;
    if (!this.listQuery.post_id) {
      this.listQuery.post_id = "";
    }
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/company/resumeList",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleInfo(row) {
      this.dataInfo = Object.assign({}, row);
      console.log(row);
      this.showDataInfo = true;
    },
    show(id) {
      this.form.post_resume_id = id;
      this.dialogFormVisible = true;
    },
    resumePass() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.btnLoading = true;
          this.$confirm("确定要通过该简历, 是否继续?", "提示", {
            type: "warning"
          })
            .then(() => {
              request({
                url: "/api/backend/company/resumePass",
                method: "post",
                data: this.form
              }).then(() => {
                this.getList();
                this.btnLoading = false;
                this.dialogFormVisible = false;
                this.$message({
                  type: "success",
                  message: "操作成功"
                });
              });
            })
            .catch(() => {
              this.btnLoading = false;
            });
        }
      });
    },
    resumeRefuse(val) {
      this.$confirm("确定要拒绝该简历, 是否继续?", "提示", {
        type: "warning"
      })
        .then(() => {
          request({
            url: "/api/backend/company/resumeRefuse",
            method: "post",
            data: { id: val }
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功"
            });
          });
        })
        .catch(() => {});
    },
     resumeDel(val) {
      this.$confirm("确定要删除该简历, 是否继续?", "提示", {
        type: "warning"
      })
        .then(() => {
          request({
            url: "/api/backend/company/resumeDel",
            method: "post",
            data: { id: val }
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功"
            });
          });
        })
        .catch(() => {});
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
.fs-16 {
    > label {
        font-size: 16px;
        
    }
    > div {
        padding-left: 20px;
        // padding-top: 20px;
        label {
            font-weight: normal;
            width: 60px !important;
            padding: 0 !important;
            text-align: left;
           
        }
    }
}
</style>
