<template>
  <div class="app-container">
    <el-form ref="form" :rules="rules" :model="form" label-width="150px">
      <el-form-item label="学校" prop="school_id">
        <el-select
          v-model="form.school_id"
          placeholder="请选择"
          @change="getTeacherList(form.school_id)"
        >
          <el-option
            v-for="item in schools"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="上课老师" prop="teacher_id">
        <el-select v-model="form.teacher_id" placeholder="请选择">
          <el-option
            v-for="item in teachers"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="课程" prop="other_course_id">
        <el-select v-model="form.other_course_id" placeholder="请选择">
          <el-option
            v-for="item in courses"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="上课地点" prop="classroom">
        <el-input v-model="form.classroom" placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item label="年度" prop="start_school_year">
        <el-date-picker
          v-model="form.start_school_year"
          type="year"
          value-format="yyyy"
          placeholder="选择年"
          style="width: 200px"
        >
        </el-date-picker>
        -
        <el-date-picker
          v-model="form.end_school_year"
          type="year"
          value-format="yyyy"
          placeholder="选择年"
          style="width: 200px"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="学期" prop="semester">
        <el-select v-model="form.semester" placeholder="请选择">
          <el-option label="第一学期" :value="1"> </el-option>
          <el-option label="第二学期" :value="2"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="日期" prop="start_at">
        <el-date-picker
          v-model="value1"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 500px"
          value-format="yyyy-MM-dd"
          @change="change1($event, key)"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="上课时间" prop="schedule">
        <div
          class="Datebox"
          v-for="(item, key) in form.schedule"
          :key="item.id"
        >
          <el-select v-model="item.week_index" placeholder="请选择星期">
            <el-option
              v-for="item in week"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          上下课时间:
          <el-time-picker
            is-range
            v-model="item.value"
            range-separator="至"
            value-format="HH:mm:[00]"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            style="width: 400px"
            @change="change($event, key)"
          >
          </el-time-picker>
          <i class="el-icon-circle-close" @click="Dele(key)"></i>
        </div>
        <el-button @click="addClassDate" style="margin-top: 10px"
          >添加上课时间</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button @click="$router.go(-1)">返回</el-button>
        <el-button type="primary" @click="submit" :loading="btnLoading"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import Tinymce from "@/components/Tinymce";
import request from "@/utils/request";
export default {
  components: {
    Tinymce,
  },
  data() {
    return {
      input: "",
      value1: "",
      value2: "",
      schools: [],
      teachers: [],
      courses: [],
      form: {
        id: null,
        school_id: "",
        teacher_id: "",
        other_course_id: "",
        classroom: "",
        start_school_year: "",
        end_school_year: "",
        semester: "",
        start_at: "",
        end_at: "",
        schedule: [
          {
            value: null,
            week_index: "",
            start_at: "",
            end_at: "",
          },
        ],
      },
      rules: {
        school_id: [{ required: true, message: "请选择学校", trigger: "blur" }],
        teacher_id: [
          { required: true, message: "请选择老师", trigger: "blur" },
        ],
        other_course_id: [
          { required: true, message: "请选择课程", trigger: "blur" },
        ],
        classroom: [
          { required: true, message: "上课地点不能为空", trigger: "blur" },
        ],
        schedule: [
          { required: true, message: "上课时间不能为空", trigger: "change" },
        ],
        start_at: [
          { required: true, message: "日期不能为空", trigger: "blur" },
        ],
        semester: [{ required: true, message: "请选择学期", trigger: "blur" }],
        start_school_year: [
          { required: true, message: "请选择开始年度", trigger: "blur" },
        ],
      },
      week: [
        {
          label: "星期一",
          value: 1,
        },
        {
          label: "星期二",
          value: 2,
        },
        {
          label: "星期三",
          value: 3,
        },
        {
          label: "星期四",
          value: 4,
        },
        {
          label: "星期五",
          value: 5,
        },
        {
          label: "星期六",
          value: 6,
        },
        {
          label: "星期日",
          value: 0,
        },
      ],
      value: "",
      btnLoading: false,
    };
  },
  created() {
    this.getSchoolList();
    this.getCourseList();
    if (this.$route.query.id) {
      this.form.id = this.$route.query.id;
      this.getDetail(this.form.id);
    }
  },
  mounted() {},
  methods: {
    addClassDate() {
      this.form.schedule.push({ week_index: "", start_at: "", end_at: "" });
    },
    change(e, key) {
      this.form.schedule[key].start_at = e[0];
      this.form.schedule[key].end_at = e[1];
    },
    change1(e, key) {
      this.form.start_at = e[0];
      this.form.end_at = e[1];
    },
    Dele(key) {
      console.log(key);
      this.form.schedule.splice(key, 1);
    },
    submit() {
      this.btnLoading = true;
      if (!this.form.end_school_year) {
        this.$message({
          showClose: true,
          message: "结束年度不能为空,请选择",
          type: "warning",
        });
        this.btnLoading = false;
        return;
      }
      this.form.schedule.forEach((e, i) => {
        this.$delete(this.form.schedule[i], "value");
      });
      this.$refs["form"].validate((valid) => {
        if (valid) {
          request({
            url: "/api/backend/otherCourse/schoolScheduleStore",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.$router.go(-1);
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    //  获取学校列表
    getSchoolList() {
      request({
        url: "/api/backend/school/list",
        method: "get",
        params: { limit: 9999999 },
      }).then((response) => {
        this.schools = response.data.data;
      });
    },
    getTeacherList(school_id) {
      request({
        url: "/api/backend/school/teacherList",
        method: "get",
        params: { limit: 9999999, school_id: school_id },
      }).then((response) => {
        this.teachers = response.data.data;
      });
    },
    getCourseList() {
      this.listLoading = true;
      request({
        url: "/api/backend/otherCourse/list",
        method: "get",
        params: {
          limit: 999999999999,
        },
      }).then((response) => {
        this.courses = response.data.data;
      });
    },
    getDetail(id) {
      request({
        url: "/api/backend/otherCourse/schoolScheduleDetail",
        method: "get",
        params: {
          id,
        },
      }).then((response) => {
        this.form = response.data;
        this.value1 = [this.form.start_at, this.form.end_at];
        this.form.schedule.forEach((e, i) => {
          this.form.schedule[i]["value"] = [e.start_at, e.end_at];
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  display: flex;
  flex-wrap: wrap;
  .tabs {
    position: relative;
    border: 1px solid #d3d3d3;
    padding: 0 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    .el-icon-circle-close {
      position: absolute;
      color: red;
      top: -9px;
      right: -9px;
      font-size: 18px;
    }
  }
}
.btnBox {
  margin-top: 20px;
}
.Datebox {
  position: relative;
  width: 60%;
  padding: 10px;
  border: 1px solid #d3d3d3;
  margin-bottom: 20px;
}
.el-input {
  width: 500px;
}
.el-icon-circle-close {
  position: absolute;
  top: -13px;
  right: -12px;
  font-size: 25px;
  color: red;
}
</style>