import Vue from "vue";
import Router from "vue-router";
// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

Vue.use(Router);

/* Layout */
import Layout from "../views/layout/Layout";

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
**/

//所有权限通用路由表
//如首页和登录页和一些不用权限的公用页面
export const constantRouterMap = [
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true
  },
  {
    path: "/error/401",
    component: () => import("@/views/error/401"),
    hidden: true
  },
  {
    path: "/error/404",
    component: () => import("@/views/error/404"),
    hidden: true
  },
  {
    path: "",
    component: Layout,
    name: "Index",
    // hidden: true,
    children: [
      {
        path: "/",
        name: "Dashboard",
        meta: { title: "首页", icon: "dashboard", affix: true },
        component: () => import("@/views/dashboard/index")
      }
    ]
  },
  {
    path: "",
    component: Layout,
    name: "Info",
    hidden: true,
    children: [
      {
        path: "/info",
        component: () => import("@/views/info/index")
      }
    ]
  },
  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/redirect/:path*",
        component: () => import("@/views/redirect/index")
      }
    ]
  }
];

//实例化vue的时候只挂载constantRouter
export default new Router({
  mode: "history",
  routes: constantRouterMap
});

//异步挂载的路由
//动态需要根据权限加载的路由表
//meta无permissions字段则不做权限控制
export const asyncRouterMap = [
  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "user",
    meta: { title: "用户管理", permissions: ["admin","users"], icon: "users" },
    children: [
      {
        path: "/user/list",
        name: "users",
        component: () => import("@/views/user/index"),
        meta: { title: "用户列表", permissions: ["admin","users"] }
      }
    ]
  },

  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "course",
    meta: { title: "课程管理", permissions: ["admin","courseCategories","lecturers","courses","comments"], icon: "content" },
    children: [
      {
        path: "/course/categories",
        name: "courseCategories",
        component: () => import("@/views/course/categories"),
        meta: { title: "课程分类", permissions: ["admin","courseCategories"] }
      },
      {
        path: "/course/lecturers",
        name: "lecturers",
        component: () => import("@/views/course/lecturers"),
        meta: { title: "讲师列表", permissions: ["admin","lecturers"] }
      },
      {
        path: "/course/list",
        name: "courses",
        component: () => import("@/views/course/list"),
        meta: { title: "课程列表", permissions: ["admin","courses"] }
      },
      {
        path: "/course/catalogs",
        name: "courseCatalogs",
        hidden: true,
        component: () => import("@/views/course/catalogs"),
        meta: { title: "课程目录", permissions: ["admin","courses"] }
      },
      {
        path: "/course/buyerList",
        name: "buyerList",
        hidden: true,
        component: () => import("@/views/course/buyerList"),
        meta: { title: "购买人列表", permissions: ["admin","courses"] }
      },
      {
        path: "/course/comments",
        name: "comments",
        component: () => import("@/views/course/comments"),
        meta: { title: "评论列表", permissions: ["admin","comments"] }
      },
    ]
  },

  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "article",
    meta: { title: "资讯管理", permissions: ["admin","articles"], icon: "content" },
    children: [
      {
        path: "/article/list",
        name: "articles",
        component: () => import("@/views/article/list"),
        meta: { title: "资讯列表", permissions: ["admin","articles"] }
      },
    ]
  },

  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "recruit",
    meta: { title: "招聘管理", permissions: ["admin","postCategories","companies","posts","resumes"], icon: "content" },
    children: [
      {
        path: "/recruit/postCategories",
        name: "postCategories",
        component: () => import("@/views/recruit/postCategories"),
        meta: { title: "岗位类型", permissions: ["admin","postCategories"] }
      },
      {
        path: "/recruit/companies",
        name: "companies",
        component: () => import("@/views/recruit/companies"),
        meta: { title: "公司列表", permissions: ["admin","companies"] }
      },
      {
        path: "/recruit/posts",
        name: "posts",
        component: () => import("@/views/recruit/posts"),
        meta: { title: "职位管理", permissions: ["admin","posts"] }
      },
      {
        path: "/recruit/resumes",
        name: "resumes",
        component: () => import("@/views/recruit/resumes"),
        meta: { title: "简历列表", permissions: ["admin","resumes"] }
      },
    ]
  },

  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "activity",
    meta: { title: "活动管理", permissions: ["admin","activities"], icon: "content" },
    children: [
      {
        path: "/activities/list",
        name: "activities",
        component: () => import("@/views/activities/list"),
        meta: { title: "活动列表", permissions: ["admin","activities"] }
      },
      {
        path: "/activities/enroll",
        name: "enroll",
        hidden: true,
        component: () => import("@/views/activities/enroll"),
        meta: { title: "查看报名", permissions: ["admin","activities"] }
      },
    ]
  },

  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "website",
    meta: { title: "建站管理", permissions: ["admin","serviceTerms","disclaimers","privacyAgreement","vipIntroduce","about","beenSetting",'setting'], icon: "table" },
    children: [
      {
        path: "/website/serviceTerms",
        name: "serviceTerms",
        component: () => import("@/views/website/serviceTerms"),
        meta: { title: "服务条款", permissions: ["admin","serviceTerms"] }
      },
      {
        path: "/website/disclaimers",
        name: "disclaimers",
        component: () => import("@/views/website/disclaimers"),
        meta: { title: "免责声明", permissions: ["admin","disclaimers"] }
      },
      {
        path: "/website/privacyAgreement",
        name: "privacyAgreement",
        component: () => import("@/views/website/privacyAgreement"),
        meta: { title: "用户隐私协议", permissions: ["admin","privacyAgreement"] }
      },
      {
        path: "/website/vipIntroduce",
        name: "vipIntroduce",
        component: () => import("@/views/website/vipIntroduce"),
        meta: { title: "VIP权限介绍", permissions: ["admin","vipIntroduce"] }
      },
      {
        path: "/website/about",
        name: "about",
        component: () => import("@/views/website/about"),
        meta: { title: "关于我们", permissions: ["admin","about"] }
      },
      {
        path: "/website/beenSetting",
        name: "beenSetting",
        component: () => import("@/views/website/beenSetting"),
        meta: { title: "全豆设置", permissions: ["admin","beenSetting"] }
      },
      {
        path: '/website/setting',
        name: 'setting',
        component: () => import('@/views/website/setting'),
        meta: { title: '审核状态' , permissions: ["admin","setting"] }
      },
    ]
  },

  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "operate",
    meta: { title: "运营管理", permissions: ["admin","banners","activityEject","couponList","feedback"], icon: "nested" },
    children: [
      {
        path: "/operate/banners",
        name: "banners",
        component: () => import("@/views/operate/banners"),
        meta: { title: "轮播图管理", permissions: ["admin","banners"] }
      },
      {
        path: "/operate/activityEject",
        name: "activityEject",
        component: () => import("@/views/operate/activityEject"),
        meta: { title: "活动弹窗", permissions: ["admin","activityEject"] }
      },
      {
        path: "/operate/couponList",
        name: "couponList",
        component: () => import("@/views/operate/couponList"),
        meta: { title: "优惠券列表",permissions: ["admin","couponList"] }
      },
      {
        path: "/operate/receive",
        name: "receive",
        hidden: true,
        component: () => import("@/views/operate/receive"),
        meta: { title: "领取人列表" ,permissions: ["admin","couponList"]}
      },
      {
        path: "/operate/canReceiveList",
        name: "canReceiveList",
        hidden: true,
        component: () => import("@/views/operate/canReceiveList"),
        meta: { title: "赠送优惠券",permissions: ["admin","couponList"] }
      },
      {
        path: "/operate/useCouponRecord",
        name: "useCouponRecord",
        hidden: true,
        component: () => import("@/views/operate/useCouponRecord"),
        meta: { title: "优惠券使用记录",permissions: ["admin","couponList"] }
      },
      {
        path: '/operate/feedback',
        name: 'feedback',
        component: () => import('@/views/operate/feedback'),
        meta: { title: '意见反馈' , permissions: ["admin","feedback"]}
      },
      {
        path: '/operate/feedbackDetailedInfo',
        name: 'FeedbackDetailedInfo',
        hidden: true,
        component: () => import('@/views/operate/feedbackDetailedInfo'),
        meta: { title: '查看详情' , permissions: ["admin","feedback"]}
      },
    ]
  },

  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "finance",
    meta: { title: "财务管理", permissions: ["admin","userAccountLog","rechargeLog","skillLog"], icon: "example" },
    children: [
      {
        path: "/finance/userAccountLog",
        name: "userAccountLog",
        component: () => import("@/views/finance/userAccountLog"),
        meta: { title: "财务流水", permissions: ["admin","userAccountLog"] }
      },
      {
        path: "/finance/rechargeLog",
        name: "rechargeLog",
        component: () => import("@/views/finance/rechargeLog"),
        meta: { title: "充值流水", permissions: ["admin","rechargeLog"] }
      },
      {
        path: "/finance/skillLog",
        name: "skillLog",
        component: () => import("@/views/finance/skillLog"),
        meta: { title: "技能课程流水", permissions: ["admin","skillLog"] }
      },
    ]
  },


  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "E-sportsManagement",
    meta: { title: "电竞管理", permissions: ["admin","schoolManagement","studentManagement","teacherElectronic","skillCourse","curriculumList"], icon: "example" },
    children: [
      {
        path: "/E-sportsManagement/schoolManagement",
        name: "schoolManagement",
        component: () => import("@/views/E-sportsManagement/schoolManagement"),
        meta: { title: "学校管理", permissions: ["admin","schoolManagement"] }
      },
      {
        path: "/E-sportsManagement/studentManagement",
        name: "studentManagement",
        component: () => import("@/views/E-sportsManagement/studentManagement"),
        meta: { title: "学生管理", permissions: ["admin","studentManagement"] }
      },
      {
        path: "/E-sportsManagement/teacherElectronic",
        name: "teacherElectronic",
        component: () => import("@/views/E-sportsManagement/teacherElectronic"),
        meta: { title: "电竞教师管理", permissions: ["admin","teacherElectronic"] }
      },
      {
        path: "/E-sportsManagement/teacherAllot",
        name: "teacherAllot",
        hidden: true,
        component: () => import("@/views/E-sportsManagement/teacherAllot"),
        meta: { title: "学校分配", permissions: ["admin","teacherElectronic"] }
      },
      {
        path: "/E-sportsManagement/skillCourse",
        name: "skillCourse",
        component: () => import("@/views/E-sportsManagement/skillCourse"),
        meta: { title: "技能课程管理", permissions: ["admin","skillCourse"] }
      },
      {
        path: "/E-sportsManagement/skillCourse_edit",
        name: "skillCourse_edit",
        hidden: true,
        component: () => import("@/views/E-sportsManagement/skillCourse_edit"),
        meta: { title: "技能课程_添加/修改", permissions: ["admin","skillCourse"] }
      },
      {
        path: "/E-sportsManagement/curriculumList",
        name: "curriculumList",
        component: () => import("@/views/E-sportsManagement/curriculumList"),
        meta: { title: "课程排班列表", permissions: ["admin","curriculumList"] }
      },
      {
        path: "/E-sportsManagement/curriculumList_edit",
        name: "curriculumList_edit",
        hidden: true,
        component: () => import("@/views/E-sportsManagement/curriculumList_edit"),
        meta: { title: "课程排班_添加/修改", permissions: ["admin","curriculumList"] }
      },
      {
        path: "/E-sportsManagement/lookApply",
        name: "lookApply",
        hidden: true,
        component: () => import("@/views/E-sportsManagement/lookApply"),
        meta: { title: "查看报名", permissions: ["admin","curriculumList"] }
      },
      // {
      //   path: "/E-sportsManagement/teacherManagement",
      //   name: "teacherManagement",
      //   component: () => import("@/views/E-sportsManagement/teacherManagement"),
      //   meta: { title: "教师管理", permissions: ["admin"] }
      // },
      // {
      //   path: "/E-sportsManagement/expenseManagement",
      //   name: "expenseManagement",
      //   component: () => import("@/views/E-sportsManagement/expenseManagement"),
      //   meta: { title: "费用管理", permissions: ["admin"] }
      // }
    ]
  },


  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "system",
    meta: { title: "系统管理", permissions: ["admin", "role"], icon: "system" },
    children: [
      // {
      //   path: "/roles",
      //   name: "role",
      //   component: () => import("@/views/role/index"),
      //   meta: { title: "角色列表", permissions: ["role"] }
      // },
      {
        path: "/admin/list",
        name: "admin",
        component: () => import("@/views/admin/index"),
        meta: { title: "管理员列表", permissions: ["admin"] }
      }
    ]
  },

  { path: "*", redirect: "/error/404", hidden: true }
];
