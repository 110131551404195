<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate">新增</el-button>
      <el-input v-model="listQuery.title" placeholder="请输入目录标题" style="width: 200px;" class="filter-item" clearable/>
      <el-select v-model="listQuery.is_on" placeholder="状态" clearable style="width: 150px" class="filter-item">
        <el-option label="正常" :value="1"/>
        <el-option label="已下架" :value="0"/>
      </el-select>
      <el-button class="filter-item" type="primary" @click="handleFilter">搜索</el-button>
      <el-button class="filter-item" type="warning" @click="$router.go(-1)" style="float:right">返回</el-button>
    </div>
    <el-table
            v-loading="listLoading"
            :data="list"
            element-loading-text="Loading"
            border
            fit
            highlight-current-row>
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="目录标题" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.title }}
        </template>
      </el-table-column>
      <el-table-column label="视频" min-width="200" align="center">
      <template slot-scope="scope">
        <video v-if="scope.row.video" :src="scope.row.video" controls style="width:200px"></video>
      </template>
      </el-table-column>
      <el-table-column label="排序" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.sort_index }}
        </template>
      </el-table-column>
      <el-table-column label="浏览人数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.read_num }}
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_on == 1" type="success">正常</el-tag>
          <el-tag v-else type="danger">已下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center" class-name="small-padding fixed-width" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)">修改</el-button>

          <el-button v-if="scope.row.is_on == 1" size="mini" type="danger" @click="handleStatus(scope.row.id, '下架')">下架</el-button>
          <el-button v-else size="mini" type="success" @click="handleStatus(scope.row.id, '上架')">上架</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
    </div>

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form ref="form" :rules="rules" :model="form" label-width="80px">
        <el-form-item label="目录标题" prop="title">
          <el-input type="text" v-model="form.title" clearable />
        </el-form-item>
        <el-form-item label="视频" prop="video">
          <!-- <upload-one v-model="form.video" @getDuration="getDuration"></upload-one> -->
          <upload-video v-model="form.video" @getDuration="getDuration" width="200px" height="200px"></upload-video>
          <span style="color:red">请等待视频上传完成后再保存</span>
        </el-form-item>
        <el-form-item label="视频时长" prop="time">
          <el-input type="text" v-model="form.time" clearable :disabled="true"/>
        </el-form-item>
        <el-form-item label="排序" prop="sort_index">
          <el-input-number v-model="form.sort_index" :min="1" :max="9999"></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

  import request from '@/utils/request'
  import UploadVideo from '@/components/UploadVideo';
  export default {
    components:{
      UploadVideo
    },
    data() {
      return {
        list: null,
        total: null,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 10,
          course_id: '',
          title: '',
          is_on: '',
        },
        dialogFormVisible: false,
        dialogStatus: '',
        textMap: {
          update: '修改',
          create: '新增'
        },
        form: {
          id: '',
          course_id: '',
          title: '',
          video: '',
          time: '',
          sort_index: 1,
        },
        rules: {
          title: [{ required: true, message: '目录标题不能为空', trigger: 'change' }],
          video: [{ required: true, message: '视频不能为空', trigger: 'change' }],
          time: [{ required: true, message: '视频时长不能为空', trigger: 'change' }],
          sort_index: [{ required: true, message: '排序不能为空', trigger: 'change' }],
        },
        btnLoading: false,
        course_id: '',

        
      }
    },
    created() {
      this.course_id = this.listQuery.course_id = this.$route.query.course_id
      if(!this.course_id){
        this.$message.warning('请先选择课程')
        setTimeout(()=>{
          this.$router.push(`/course/list`);
        },1000)
        return
      }
      this.getList()
    },
    watch:{
      'form.video'(val){
        this.getDuration()
      }
    },
    methods: {
      // 获取时长
      getDuration(time){
       
        if(time) this.form.time = time.toFixed(2)
        
      },
      getList() {
        this.listLoading = true
        request({
          url: '/api/backend/course/catalogList',
          method: 'get',
          params: this.listQuery
        }).then(response => {
          this.list = response.data.data
          this.total = response.data.total
          this.listLoading = false
        })
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      handleSizeChange(val) {
        this.listQuery.limit = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.listQuery.page = val
        this.getList()
      },
      resetForm() {
        this.form = {
          id: '',
          course_id: '',
          title: '',
          video: '',
          time: '',
          sort_index: 1,
        }
        this.btnLoading = false
      },
      handleCreate() {
        this.resetForm()
        this.dialogStatus = 'create'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['form'].clearValidate()
        })
      },
      handleUpdate(row) {
        this.resetForm()
        this.form = Object.assign({}, row)
        this.dialogStatus = 'update'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['form'].clearValidate()
        })
      },
      saveData() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.form.course_id = this.course_id
            if(!this.form.course_id){
              this.$message.warning('请先选择课程')
              setTimeout(()=>{
                this.$router.push(`/course/list`);
              },1000)
              return
            }

            this.btnLoading = true
            
            request({
              url: '/api/backend/course/catalogStore',
              method: 'post',
              data: this.form
            }).then(response => {
              this.btnLoading = false
              this.dialogFormVisible = false
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.getList();
            }).catch((err) => {
              this.btnLoading = false
            })
          }
        })
      },
      //上下架
      handleStatus(val,text) {
        this.$confirm('此操作将'+text+'该目录, 是否继续?', '提示', {
          type: 'warning'
        }).then(() => {
          request({
            url: '/api/backend/course/catalogUpDown',
            method: 'post',
            data: {id: val}
          }).then(()=>{
            this.getList()
            this.$message({
              type: 'success',
              message: '操作成功'
            });
          })
        }).catch(() => {});
      },
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

</style>
