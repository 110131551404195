<template>
  <div class="app-container">
    <div class="filter-container"></div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column align="center" label="序号" width="95">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="头像" min-width="100" align="center">
        <template slot-scope="scope">
          <img
            v-if="scope.row.user.avatar"
            :src="scope.row.user.avatar"
            alt=""
            class="user-avatar"
          />
          <i v-else>-</i>
        </template>
      </el-table-column>
      <el-table-column label="昵称" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.user.nickname }}
        </template>
      </el-table-column>
      <el-table-column label="联系电话" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.user.phone }}
        </template>
      </el-table-column>
      <el-table-column label="反馈" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.content }}
        </template>
      </el-table-column>
      <el-table-column label="是否已处理" min-width="60" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_handle == 1" type="success">是</el-tag>
          <el-tag v-else type="warning">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handle(scope.row.id)" v-if="scope.row.is_handle == 0"
            >处理</el-button
          >
          <el-button size="mini" @click="goDetails(scope.row.id)"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!-- <el-dialog
      :title="dialogTitle"
      :visible.sync="showDialog"
      v-loading="btnLoading"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
        label-width="100px"
      >
        <el-form-item label="图片" prop="title">
          <UploadList
            width="200px"
            height="200px"
            v-model="form.images"
            :maxLength="6"
            :rules="[{ required: true, message: '轮播图不能为空' }]"
          ></UploadList>
        </el-form-item>
        <el-form-item label="权重" prop="weight">
          <el-input type="number" v-model="form.weight" />
        </el-form-item>
        <el-form-item label="跳转模块" prop="weight">
          <el-input type="number" v-model="form.weight" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >提交</el-button
        >
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import request from "@/utils/request";
import Tinymce from "@/components/Tinymce";
import UploadList from "@/components/UploadList/index.vue";
export default {
  components: { Tinymce, UploadList },
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 10,
      },
      total: 0,
      list: null,
      listLoading: false,
      showDialog: false,
      dialogTitle: "",
      form: {
        id: "",
        title: "",
        weight: 0,
        content: "",
      },
      rules: {
        title: [{ required: true, message: "标题不能为空", trigger: "blur" }],
        weight: [{ required: true, message: "权重不能为空", trigger: "blur" }],
        content: [
          { required: true, message: "详细介绍不能为空", trigger: "blur" },
        ],
      },
      btnLoading: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/feedback/feedbackList",
        method: "get",
        data: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    resetForm() {
      this.form = {
        id: "",
        title: "",
        weight: 0,
        content: "",
      };
      // this.btnLoading = false
    },
    onAdd(row) {
      this.resetForm();
      if (row.id) {
        this.dialogTitle = "修改";
        this.form.id = row.id;
        this.getDetails();
      } else {
        this.dialogTitle = "新增";
        if (this.$refs.content) this.$refs.content.setContent("");
      }
      this.showDialog = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    goDetails(id) {
      this.$router.push("/operate/feedbackDetailedInfo?id=" + id);
    },
    handle(id) {
      this.btnLoading = true;
      request({
        url: `/api/backend/feedback/handle`,
        method: "post",
        data: {
          id:id
        },
      })
        .then((response) => {
          this.$notify({
            title: "成功",
            message: "处理成功",
            type: "success",
            duration: 2000,
          });
          this.getList();
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
    handleDel(val) {
      this.$confirm("确定要删除, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/setting/deleteHelpManual",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
  },
};
</script>


<style>
.upload-block {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 120px;
  height: 120px;
}
.uploaded-img {
  width: 120px;
  height: 120px;
}
.upload-block:hover {
  border-color: #409eff;
}
.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
</style>
