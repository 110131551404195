<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate">新增</el-button>
      <el-input v-model="listQuery.title" placeholder="请输入资讯标题" style="width: 200px;" class="filter-item" clearable/>
      <el-select v-model="listQuery.is_on" placeholder="状态" clearable style="width: 150px" class="filter-item">
        <el-option label="正常" :value="1"/>
        <el-option label="已下架" :value="0"/>
      </el-select>
      <el-select v-model="listQuery.sort_type" placeholder="排序" clearable style="width: 150px" class="filter-item">
        <el-option label="按时间排序" value="1"/>
        <el-option label="按浏览人数排序" value="2"/>
        <el-option label="按分享人数排序" value="3"/>
        <el-option label="按收藏人数排序" value="4"/>
      </el-select>
      <el-button class="filter-item" type="primary" @click="handleFilter">搜索</el-button>
    </div>
    <el-table
            v-loading="listLoading"
            :data="list"
            element-loading-text="Loading"
            border
            fit
            highlight-current-row>
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="资讯ID" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="资讯标题" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.title }}
        </template>
      </el-table-column>
      <el-table-column label="封面" min-width="110" align="center">
        <template slot-scope="scope">
          <!-- <img v-if="scope.row.cover" :src="scope.row.cover" class="image-url"> -->
          <el-image class="image-url-150" :src="scope.row.cover" fit="contain" lazy :preview-src-list="[scope.row.cover]"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="浏览人数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.read_num }}
        </template>
      </el-table-column>
      <el-table-column label="分享人数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.share_num }}
        </template>
      </el-table-column>
      <el-table-column label="收藏人数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.collection_num }}
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_on == 1" type="success">正常</el-tag>
          <el-tag v-else type="danger">已下架</el-tag>
        </template>
      </el-table-column>
         <el-table-column label="时间" min-width="110" align="center">
        <template slot-scope="scope">
            {{ scope.row.created_at }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center" class-name="small-padding fixed-width" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)">修改</el-button>

          <el-button v-if="scope.row.is_on == 1" size="mini" type="danger" @click="handleStatus(scope.row.id, '下架')">下架</el-button>
          <el-button v-else size="mini" type="success" @click="handleStatus(scope.row.id, '上架')">上架</el-button>
          <el-button size="mini" type="warning" @click="del(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
    </div>

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="资讯标题" prop="title">
          <el-input type="text" v-model="form.title" clearable />
        </el-form-item>
        <el-form-item label="封面(176*116)" prop="cover">
          <upload-one v-model="form.cover" style="width:176px;height:116px"></upload-one>
        </el-form-item>
        <el-form-item label="来源" prop="source">
          <el-input type="text" v-model="form.source" clearable />
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <tinymce :height="400" v-model="form.content" :value="form.content" ref="tinymce"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

  import request from '@/utils/request'
  import Tinymce from "@/components/Tinymce";

  export default {
    components: {
      Tinymce,
    },
    data() {
      return {
        list: null,
        total: null,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 10,
          title: '',
          is_on: '',
          sort_type: '',
        },
        dialogFormVisible: false,
        dialogStatus: '',
        textMap: {
          update: '修改',
          create: '新增'
        },
        form: {
          id: '',
          title: '',
          cover: '',
          source: '',
          content: '',
        },
        rules: {
          title: [{ required: true, message: '资讯标题不能为空', trigger: 'change' }],
          cover: [{ required: true, message: '封面不能为空', trigger: 'change' }],
          source: [{ required: true, message: '来源不能为空', trigger: 'change' }],
          content: [{ required: true, message: '内容不能为空', trigger: 'change' }],
        },
        btnLoading: false,
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.listLoading = true
        request({
          url: '/api/backend/article/list',
          method: 'get',
          params: this.listQuery
        }).then(response => {
          this.list = response.data.data
          this.total = response.data.total
          this.listLoading = false
        })
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      handleSizeChange(val) {
        this.listQuery.limit = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.listQuery.page = val
        this.getList()
      },
      resetForm() {
        this.form = {
          id: '',
          title: '',
          cover: '',
          source: '',
          content: '',
        }
        this.btnLoading = false
      },
      handleCreate() {
        this.resetForm()
        this.dialogStatus = 'create'
        this.dialogFormVisible = true
        if(this.$refs.tinymce) this.$refs.tinymce.setContent('')
        this.$nextTick(() => {
          this.$refs['form'].clearValidate()
        })
      },
      handleUpdate(row) {
        this.resetForm()
        this.form = Object.assign({}, row)
        this.dialogStatus = 'update'
        this.dialogFormVisible = true
        if(this.$refs.tinymce) this.$refs.tinymce.setContent(row.content)
        this.$nextTick(() => {
          this.$refs['form'].clearValidate()
        })
      },
      saveData() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.btnLoading = true
            request({
              url: '/api/backend/article/store',
              method: 'post',
              data: this.form
            }).then(response => {
              this.btnLoading = false
              this.dialogFormVisible = false
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.getList();
            }).catch((err) => {
              this.btnLoading = false
            })
          }
        })
      },
      //上下架
      handleStatus(val,text) {
        this.$confirm('此操作将'+text+'该资讯, 是否继续?', '提示', {
          type: 'warning'
        }).then(() => {
          request({
            url: '/api/backend/article/upDown',
            method: 'post',
            data: {id: val}
          }).then(()=>{
            this.getList()
            this.$message({
              type: 'success',
              message: '操作成功'
            });
          })
        }).catch(() => {});
      },
       //上下架
      del(val) {
        this.$confirm('此操作将删除该资讯, 是否继续?', '提示', {
          type: 'warning'
        }).then(() => {
          request({
            url: '/api/backend/article/del',
            method: 'post',
            data: {id: val}
          }).then(()=>{
            this.getList()
            this.$message({
              type: 'success',
              message: '操作成功'
            });
          })
        }).catch(() => {});
      },
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

</style>
