<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="listQuery.course_title" placeholder="请输入课程标题" style="width: 200px;" class="filter-item" clearable/>
      <el-input v-model="listQuery.comment_content" placeholder="请输入评论内容" style="width: 200px;" class="filter-item" clearable/>
      <el-button class="filter-item" type="primary" @click="handleFilter">搜索</el-button>
    </div>
    <el-table
            v-loading="listLoading"
            :data="list"
            element-loading-text="Loading"
            border
            fit
            highlight-current-row>
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="课程标题" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.course?scope.row.course.title:'' }}
        </template>
      </el-table-column>
      <el-table-column label="封面" min-width="110" align="center">
        <template slot-scope="scope">
          <img v-if="scope.row.course && scope.row.course.cover" :src="scope.row.course.cover" class="image-url">
        </template>
      </el-table-column>
      <el-table-column label="评论内容" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.content }}
        </template>
      </el-table-column>
      <el-table-column label="发布人ID" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user?scope.row.user.id:'' }}
        </template>
      </el-table-column>
      <el-table-column label="发布人头像" min-width="110" align="center">
        <template slot-scope="scope">
          <img v-if="scope.row.user && scope.row.user.avatar" :src="scope.row.user.avatar" class="user-avatar">
        </template>
      </el-table-column>
      <el-table-column label="发布人昵称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user?scope.row.user.nickname:'' }}
        </template>
      </el-table-column>
      <el-table-column label="发布时间" min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center" class-name="small-padding fixed-width" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="handleDel(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
    </div>
  </div>
</template>

<script>

  import request from '@/utils/request'

  export default {
    data() {
      return {
        list: null,
        total: null,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 10,
          course_title: '',
          comment_content: '',
        },
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.listLoading = true
        request({
          url: '/api/backend/course/commentList',
          method: 'get',
          params: this.listQuery
        }).then(response => {
          this.list = response.data.data
          this.total = response.data.total
          this.listLoading = false
        })
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      handleSizeChange(val) {
        this.listQuery.limit = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.listQuery.page = val
        this.getList()
      },
      handleDel(val) {
        this.$confirm('确定要删除, 是否继续?', '提示', {
          type: 'warning'
        }).then(() => {
          request({
            url: '/api/backend/course/delComment',
            method: 'post',
            data: {id: val}
          }).then(()=>{
            this.getList()
            this.$message({
              type: 'success',
              message: '操作成功'
            });
          })
        }).catch(() => {});
      },
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

</style>
