<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate"
        >新增</el-button
      >
      <el-input
        v-model="listQuery.title"
        placeholder="请输入课程标题"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.is_on"
        placeholder="状态"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="正常" :value="1" />
        <el-option label="已下架" :value="0" />
      </el-select>
      <el-select
        v-model="listQuery.is_recommend"
        placeholder="是否推荐"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="是" :value="1" />
        <el-option label="否" :value="0" />
      </el-select>
      <el-select
        v-model="listQuery.pay_rule"
        placeholder="付费规则"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="不免费" :value="1" />
        <el-option label="限时免费" :value="2" />
        <el-option label="会员免费" :value="3" />
      </el-select>
      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="课程ID" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="课程标题" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.title }}
        </template>
      </el-table-column>
      <el-table-column label="课程类型" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.course_category.title }}
        </template>
      </el-table-column>
      <el-table-column label="封面" min-width="110" align="center">
        <template slot-scope="scope">
          <img
            v-if="scope.row.cover"
            :src="scope.row.cover"
            class="image-url"
          />
        </template>
      </el-table-column>
      <el-table-column label="目录数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.catalog_num }}
        </template>
      </el-table-column>
      <el-table-column label="价格(元)" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.price }}
        </template>
      </el-table-column>
      <el-table-column label="浏览人数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.study_num }}
        </template>
      </el-table-column>
      <el-table-column label="购买人数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.buy_num }}
        </template>
      </el-table-column>
      <el-table-column label="付费规则" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.pay_rule == 1" type="danger">不免费</el-tag>
          <el-tag v-else-if="scope.row.pay_rule == 2" type="primary"
            >限时免费</el-tag
          >
          <el-tag v-else-if="scope.row.pay_rule == 3" type="success"
            >精选课</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_on == 1" type="success">正常</el-tag>
          <el-tag v-else type="danger">已下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="是否推荐" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_recommend == 1" type="success">是</el-tag>
          <el-tag v-else type="info">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="讲师头像" min-width="110" align="center">
        <template slot-scope="scope">
          <img
            v-if="scope.row.lecturer && scope.row.lecturer.avatar"
            :src="scope.row.lecturer.avatar"
            class="user-avatar"
          />
        </template>
      </el-table-column>
      <el-table-column label="讲师名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.lecturer ? scope.row.lecturer.name : "" }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="300"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)"
            >修改</el-button
          >

          <el-button
            v-if="scope.row.is_on == 1"
            size="mini"
            type="danger"
            @click="handleStatus(scope.row.id, '下架')"
            >下架</el-button
          >
          <el-button
            v-else
            size="mini"
            type="success"
            @click="handleStatus(scope.row.id, '上架')"
            >上架</el-button
          >

          <el-button
            size="mini"
            type="primary"
            @click="courseCatalogs(scope.row)"
            >课程目录</el-button
          >

          <el-button size="mini" type="primary" @click="buyerList(scope.row)"
            >购买人列表</el-button
          >

          <el-button
            v-if="scope.row.is_recommend == 1"
            size="mini"
            type="danger"
            @click="handleRecommend(scope.row.id, '取消推荐')"
            >取消推荐</el-button
          >
          <el-button
            v-else
            size="mini"
            type="success"
            @click="handleRecommend(scope.row.id, '推荐')"
            >推荐</el-button
          >

          <el-button
            size="mini"
            type="primary"
            @click="handlePayRule(scope.row)"
            >付费规则</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="课程标题" prop="title">
          <el-input type="text" v-model="form.title" clearable />
        </el-form-item>
        <el-form-item label="封面(334*184)" prop="cover">
          <upload-one
            v-model="form.cover"
            style="width: 334px; height: 184px"
          ></upload-one>
        </el-form-item>
        <el-form-item label="价格(元)" prop="price">
          <el-input
            v-model="form.price"
            :min="1"
            :max="100000"
            @input="
              form.price = form.price
                .replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g, '')
                .replace('.', '')
                .replace(/\./g, '')
                .replace('$#$', '')
                .replace(/\.{2,}/g, '')
                .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2')
            "
          ></el-input>
        </el-form-item>
        <el-form-item label="付费规则" prop="pay_rule">
          <!-- <el-radio v-model="form.pay_rule" :label="1">不免费</el-radio> -->
          <el-radio v-model="form.pay_rule" :label="2">限时免费</el-radio>
          <el-radio v-model="form.pay_rule" :label="3">精选课</el-radio>
        </el-form-item>
        <el-form-item label="讲师" prop="lecturer_id">
          <el-select v-model="form.lecturer_id" clearable>
            <el-option
              v-for="(item, index) in lecturers"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="课程分类" prop="course_category_id">
          <el-select v-model="form.course_category_id" clearable>
            <el-option
              v-for="(item, index) in courseCategories"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="详细介绍" prop="introduce">
          <tinymce
            :height="400"
            v-model="form.introduce"
            :value="form.introduce"
            ref="tinymce"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <!-- 付费规则 -->
    <el-dialog
      title="修改付费规则"
      :visible.sync="dialogPayRuleVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="formPayRule"
        :rules="rulesPayRule"
        :model="formPayRule"
        label-width="80px"
      >
        <el-form-item label="付费规则" prop="pay_rule">
          <el-radio v-model="formPayRule.pay_rule" :label="1">不免费</el-radio>
          <el-radio v-model="formPayRule.pay_rule" :label="2"
            >限时免费</el-radio
          >
          <el-radio v-model="formPayRule.pay_rule" :label="3">精选课</el-radio>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogPayRuleVisible = false">取消</el-button>
        <el-button type="primary" @click="saveDataPayRule" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <!-- 付费规则 -->
  </div>
</template>

<script>
import request from "@/utils/request";
import Tinymce from "@/components/Tinymce";

export default {
  components: {
    Tinymce,
  },
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        title: "",
        is_on: "",
        is_recommend: "",
        pay_rule: "",
      },
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "修改",
        create: "新增",
      },
      form: {
        id: "",
        title: "",
        cover: "",
        price: "",
        pay_rule: 1,
        lecturer_id: "",
        course_category_id: "",
        introduce: "",
      },
      rules: {
        title: [
          { required: true, message: "课程标题不能为空", trigger: "change" },
        ],
        cover: [{ required: true, message: "封面不能为空", trigger: "change" }],
        price: [{ required: true, message: "价格不能为空", trigger: "change" }],
        pay_rule: [
          { required: true, message: "付费规则不能为空", trigger: "change" },
        ],
        lecturer_id: [
          { required: true, message: "讲师不能为空", trigger: "change" },
        ],
        course_category_id: [
          { required: true, message: "课程分类id不能为空", trigger: "change" },
        ],
        introduce: [
          { required: true, message: "详细介绍不能为空", trigger: "change" },
        ],
      },
      btnLoading: false,
      lecturers: [],
      courseCategories: [],
      //付费规则
      dialogPayRuleVisible: false,
      formPayRule: {
        id: "",
        pay_rule: 1,
      },
      rulesPayRule: {
        pay_rule: [
          { required: true, message: "付费规则不能为空", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.getList();
    this.getLecturers();
    this.getCourseCategories();
  },

  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/course/list",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    resetForm() {
      this.form = {
        id: "",
        title: "",
        cover: "",
        price: "",
        pay_rule: 1,
        lecturer_id: "",
        course_category_id: "",
        introduce: "",
      };
      this.btnLoading = false;
    },
    handleCreate() {
      this.resetForm();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      if (this.$refs.tinymce) this.$refs.tinymce.setContent("");
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    handleUpdate(row) {
      this.resetForm();
      this.form = Object.assign({}, row);
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      if (this.$refs.tinymce) this.$refs.tinymce.setContent(row.introduce);
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    saveData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/backend/course/store",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    //获取讲师
    getLecturers() {
      request({
        url: "/api/backend/course/lecturerList",
        method: "get",
        params: {
          page: 1,
          limit: 100000,
          name: "",
        },
      }).then((response) => {
        this.lecturers = response.data.data;
      });
    },
    //获取讲师
    getCourseCategories() {
      request({
        url: "/api/backend/course/allCategories",
        method: "get",
        params: {},
      }).then((response) => {
        this.courseCategories = response.data;
      });
    },
    //上下架
    handleStatus(val, text) {
      this.$confirm("此操作将" + text + "该课程, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/course/upDown",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    //课程目录
    courseCatalogs(row) {
      this.$router.push(`/course/catalogs?course_id=${row.id}`);
    },
    //购买人列表
    buyerList(row) {
      this.$router.push(`/course/buyerList?course_id=${row.id}`);
    },
    //推荐
    handleRecommend(val, text) {
      this.$confirm("此操作将" + text + "该课程, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/course/recommend",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    //付费规则
    resetFormPayRule() {
      this.formPayRule = {
        id: "",
        pay_rule: 1,
      };
      this.btnLoading = false;
    },
    handlePayRule(row) {
      this.resetFormPayRule();
      this.formPayRule.id = row.id;
      this.formPayRule.pay_rule = row.pay_rule;
      this.dialogPayRuleVisible = true;
      this.$nextTick(() => {
        this.$refs["formPayRule"].clearValidate();
      });
    },
    saveDataPayRule() {
      this.$refs["formPayRule"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/backend/course/payRuleStore",
            method: "post",
            data: this.formPayRule,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogPayRuleVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
