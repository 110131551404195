<template>
  <div class="app-container" v-loading="listLoading">
    <div class="item">
      <div class="filter-container">
        <el-date-picker v-model="dateArrUser" type="daterange" align="right" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" class="filter-item" style="display: inline-flex;"></el-date-picker>
        <el-button class="filter-item" type="primary" @click="handleUser">搜索</el-button>
      </div>
      <!-- 用户折线图 -->
      <div class="chartBox">
        <div id="myChartUser"></div>
      </div>
    </div>
    <div class="item">
      <div class="filter-container">
        <el-date-picker v-model="dateArrCourse" type="daterange" align="right" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" class="filter-item" style="display: inline-flex;"></el-date-picker>
        <el-button class="filter-item" type="primary" @click="handleCourse">搜索</el-button>
      </div>
      <!-- 课程折线图 -->
      <div class="chartBox">
        <div id="myChartCourse"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import request from "@/utils/request";
  let echarts = require("echarts/lib/echarts");
  // 引入折线图组件
  require("echarts/lib/chart/line");
  // 引入提示框和title组件
  require("echarts/lib/component/tooltip");
  require("echarts/lib/component/title");
  require("echarts/lib/component/legend");

  export default {
    data() {
      return {
        listLoading:false,
        dateArrUser: null,
        dateArrCourse: null,
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        listQueryUser: {
          start_time: "",
          end_time: ""
        },
        lineDataUser: [],
        listQueryCourse: {
          start_time: "",
          end_time: ""
        },
        lineDataCourse: [],
      };
    },
    watch: {
      dateArrUser(newVal, oldVal) {
        if (newVal) {
          this.listQueryUser.start_time = newVal[0];
          this.listQueryUser.end_time = newVal[1];
        } else {
          this.listQueryUser.start_time = "";
          this.listQueryUser.end_time = "";
        }
      },
      dateArrCourse(newVal, oldVal) {
        if (newVal) {
          this.listQueryCourse.start_time = newVal[0];
          this.listQueryCourse.end_time = newVal[1];
        } else {
          this.listQueryCourse.start_time = "";
          this.listQueryCourse.end_time = "";
        }
      },
    },
    mounted() {
      this.month = new Date().getMonth() + 1;
      let MonthNextFirstDay = new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              1
      );
      let MonthLastDay = new Date(MonthNextFirstDay - 86400000);
      this.lastDay = MonthLastDay.getDate();
      if (this.month < 10) {
        this.listQueryUser.start_time = this.listQueryCourse.start_time =
                new Date().getFullYear() + "-" + "0" + this.month + "-" + "01";
        this.listQueryUser.end_time = this.listQueryCourse.end_time =
                new Date().getFullYear() + "-" + "0" + this.month + "-" + this.lastDay;
      } else {
        this.listQueryUser.start_time = this.listQueryCourse.start_time =
                new Date().getFullYear() + "-" + this.month + "-" + "01";
        this.listQueryUser.end_time = this.listQueryCourse.end_time =
                new Date().getFullYear() + "-" + this.month + "-" + this.lastDay;
      }
      this.dateArrUser = [this.listQueryUser.start_time, this.listQueryUser.end_time]
      this.dateArrCourse = [this.listQueryCourse.start_time, this.listQueryCourse.end_time]
      this.getAllChartUser()
      this.getAllChartCourse()
    },
    methods: {
      //获取用户所有图表折线图
      getAllChartUser() {
        if(this.listQueryUser.start_time.split(' ')[1]){
          this.listQueryUser.start_time = this.listQueryUser.start_time.split(' ')[0]
        }
        if(this.listQueryUser.end_time.split(' ')[1]){
          this.listQueryUser.end_time = this.listQueryUser.end_time.split(' ')[0]
        }
        this.listQueryUser.start_time = this.listQueryUser.start_time?this.listQueryUser.start_time+' 00:00:00':''
        this.listQueryUser.end_time = this.listQueryUser.end_time?this.listQueryUser.end_time+' 23:59:59':''
        this.listLoading = true
        // 其中一个请求抛出异常但不影响整个请求
        return Promise.all([this.getUser()]).then(()=>{
          this.listLoading = false
        })
      },
      //获取课程所有图表折线图
      getAllChartCourse() {
        if(this.listQueryCourse.start_time.split(' ')[1]){
          this.listQueryCourse.start_time = this.listQueryCourse.start_time.split(' ')[0]
        }
        if(this.listQueryCourse.end_time.split(' ')[1]){
          this.listQueryCourse.end_time = this.listQueryCourse.end_time.split(' ')[0]
        }
        this.listQueryCourse.start_time = this.listQueryCourse.start_time?this.listQueryCourse.start_time+' 00:00:00':''
        this.listQueryCourse.end_time = this.listQueryCourse.end_time?this.listQueryCourse.end_time+' 23:59:59':''
        this.listLoading = true
        // 其中一个请求抛出异常但不影响整个请求
        return Promise.all([this.getCourse()]).then(()=>{
          this.listLoading = false
        })
      },
      //获取用户数量图
      getUser() {
        return request({
          url: "/api/backend/user/rlNum",
          method: "get",
          params: {
            start_time: this.listQueryUser.start_time,
            end_time: this.listQueryUser.end_time
          }
        }).then(res => {
          this.lineDataUser = res.data;
          console.log(this.lineDataUser)
         this.drawLineUser(
                this.listQueryUser.start_time,
                this.listQueryUser.end_time,
                "myChartUser"
        );
        });
        // this.drawLineUser(
        //         this.listQueryUser.start_time,
        //         this.listQueryUser.end_time,
        //         "myChartUser"
        // );
      },
      //获取课程数量图
      getCourse() {
        return request({
          url: "/api/backend/user/buyNum",
          method: "get",
          params: {
            start_time: this.listQueryCourse.start_time,
            end_time: this.listQueryCourse.end_time
          }
        }).then(res => {
          this.lineDataCourse = res.data;
          console.log(this.lineDataCourse)
         this.drawLineCourse(
                this.listQueryCourse.start_time,
                this.listQueryCourse.end_time,
                "myChartCourse"
        );
        });
        
      },
      handleUser() {
        if(!this.listQueryUser.start_time||!this.listQueryUser.end_time){
          this.$message.warning('请选择开始和结束日期')
          return
        }
        this.getAllChartUser();
      },
      handleCourse() {
        if(!this.listQueryCourse.start_time||!this.listQueryCourse.end_time){
          this.$message.warning('请选择开始和结束日期')
          return
        }
        this.getAllChartCourse();
      },
      drawLineUser(startdate, enddate, elementId) {
        // 基于准备好的dom，初始化echarts实例
        let start_time = startdate;
        let end_time = enddate;
        let date = this.get(start_time, end_time);
        let element = echarts.init(document.getElementById(elementId));
        let temporary = [];
        for (let i = 0; i < date.length; i++) {
          temporary.push(date[i]);
        }
        console.log(start_time, end_time, temporary)
        element.setOption({
          title: {
            text: '用户'
          },
          tooltip: {
            trigger: 'axis'
          },
          color: ['#871F78','#38B0DE'],
          legend: {
            data: ['注册人数', '登录人数']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          // toolbox: {
          //   right: 50,
          //   feature: {
          //     saveAsImage: { show: true, title: '保存为图片' }
          //   }
          // },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            // data: ['周一','周二','周三','周四','周五','周六','周日']
            data: temporary
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '注册人数',
              type: 'line',
              stack: '总量',
              // data: [120, 132, 101, 134, 90, 230, 210],
              data: this.lineDataUser.r_num,
              smooth: true,
              itemStyle : {
                normal : {
                  lineStyle:{
                    color:'#871F78'
                  }
                }
              },
            },
            {
              name: '登录人数',
              type: 'line',
              stack: '总量',
              data: this.lineDataUser.l_num,
              smooth: true,
              itemStyle : {
                normal : {
                  lineStyle:{
                    color:'#38B0DE'
                  }
                }
              },
            }
          ]
        });
      },
      drawLineCourse(startdate, enddate, elementId) {
        // 基于准备好的dom，初始化echarts实例
        let start_time = startdate;
        let end_time = enddate;
        let date = this.get(start_time, end_time);
        let element = echarts.init(document.getElementById(elementId));
        let temporary = [];
        for (let i = 0; i < date.length; i++) {
          temporary.push(date[i]);
        }
        console.log(start_time, end_time, temporary)
        element.setOption({
          title: {
            text: '课程'
          },
          tooltip: {
            trigger: 'axis'
          },
          color: ['#871F78'],
          legend: {
            data: ['购买人数']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          // toolbox: {
          //   right: 50,
          //   feature: {
          //     saveAsImage: { show: true, title: '保存为图片' }
          //   }
          // },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            // data: ['周一','周二','周三','周四','周五','周六','周日']
            data: temporary
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '购买人数',
              type: 'line',
              stack: '总量',
              data: this.lineDataCourse,
              // data: this.lineData
              smooth: true,
              itemStyle : {
                normal : {
                  lineStyle:{
                    color:'#871F78'
                  }
                }
              },
            },
          ]
        });
      },
      get(day1, day2) {
        let end_day = day2.split(' ')[0].split("-")[1] + "/" +  day2.split(' ')[0].split("-")[2];
        var getDate = function(str) {
          var tempDate = new Date();
          var list = str.split("-");
          tempDate.setFullYear(list[0]);
          tempDate.setMonth(list[1] - 1);
          tempDate.setDate(list[2]);
          return tempDate;
        };
        var date1 = getDate(day1.split(' ')[0]);
        var date2 = getDate(day2.split(' ')[0]);
        if (date1 > date2) {
          var tempDate = date1;
          date1 = date2;
          date2 = tempDate;
        }
        date1.setDate(date1.getDate());
        var dateArr = [];
        var i = 0;
        while (
                !(
                        date1.getFullYear() == date2.getFullYear() &&
                        date1.getMonth() == date2.getMonth() &&
                        date1.getDate() == date2.getDate()
                )
                ) {
          var dayStr = date1.getDate().toString();
          let monthStr = date1.getMonth() + 1;
          if (dayStr.length == 1) {
            dayStr = "0" + dayStr;
          }
          if (monthStr < 10) {
            monthStr = "0" + monthStr;
          }
          dateArr[i] = monthStr + "/" + dayStr;
          i++;
          date1.setDate(date1.getDate() + 1);
        }
        dateArr.push(end_day);
        return dateArr;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .app-container {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    .item{
      width: 48%;
      margin-top: 4%;
      margin-right: 4%;
    }
    .item:nth-of-type(-n+2) {
      margin-top: 0;
    }
    .item:nth-of-type(2n) {
      margin-right: 0;
    }
    .chartBox {
      margin-top:50px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      div {
        width: 100%;
        height: 600px;
      }
    }
  }
</style>
