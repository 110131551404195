<template>
  <div>
    <!-- <el-button style="float: right; margin-right: 200px;zIndex:9999" type="primary" @click="handleGoBack" >返回</el-button> -->
    <div v-loading="Loading">
      <el-form label-width="100px" style="margin: 10px 200px" v-if="!Loading">
        <h3>{{ detail.name }}</h3>
        <el-form-item label="用户ID">
          {{detail.user_id ? detail.user_id : "-"}}
          <el-button style="float: right; margin-right: 200px;" type="primary" @click="handleGoBack" >返回</el-button>
        </el-form-item>
        <el-form-item label="头像">
          <img
            :src="detail.user.avatar"
            class="upload-list-component-image"
          />
        </el-form-item>
        <el-form-item label="昵称">{{
          detail.user ? detail.user.nickname : "-"
        }}</el-form-item>
        <el-form-item label="联系电话">{{
          detail.user ? detail.user.phone : "-"
        }}</el-form-item>
        <el-form-item label="反馈">{{
          detail.content ? detail.content : "-"
        }}</el-form-item>
        <el-form-item label="图片">
          <div v-for="(image, image_index) in detail.images" :key="image_index">
            <el-image class="image-url-150" :src="image" lazy :preview-src-list="detail.images"></el-image>
          </div>
        </el-form-item>

        <el-form-item label="是否已处理">
          <el-tag v-if="detail.is_handle == 1" type="success">是</el-tag>
          <el-tag v-else type="danger">否</el-tag></el-form-item
        >
      </el-form>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      regions: [],
      detail: {},
      region: null,
      region_img_url: [],
      id: null,
      Loading: false,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.Loading = true;
      request({
        url: `/api/backend/feedback/detail?id=${this.id}`,
        method: "get",
      })
        .then((result) => {
          this.detail = result.data;
          this.Loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.Loading = false;
        });
    },
    handle(examine_status) {
      this.Loading = true;
      request({
        url: `/api/backend/post/postReportExamine`,
        method: "post",
        data: { id: this.id, type: examine_status },
      })
        .then((result) => {
          this.getDetail();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRegionsImg() {
      console.log(this.detail.exhibition_exhibition_area);
      this.detail.exhibition_exhibition_area.forEach((v) => {
        this.region_img_url.push({
          picture: v.picture,
          id: v.exhibition_area.id,
        });
      });
    },
    showMap() {},
    changeRegion(e) {},
    handleGoBack() {
      this.$router.go(-1);
    },
  },
};
</script>
